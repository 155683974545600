@import "../../../../scss/index.scss";

.remarks-content {
  @include typography("regular", 14px);
  color: $tableBodyTitle;
}
.category-text {
  @include typography(regular, 14px);
}
.project-document {
  @include typography(regular, 14px);
  color: $viewFile;
  cursor: pointer;
  text-decoration: none;
}

.heading-button-wrapper {
  display: flex;
  justify-content: space-between;

  h2 {
    @include typography("semi-bold", 20px);
    color: $greyTitle;
  }

  .add-invoice-btn {
    background-color: $orange;
    color: $white;
    border-radius: 10px;
    @include typography("medium", 12px);
    height: 42px;
    width: 145px;
    border: none;
  }
}
.projectRepoData {
  text-align: center;
  font-size: 11px;
}

.veiw-cursor{
  cursor: pointer;
}
