@import "../../scss/index.scss";

.paddinf-left-upload {
  padding-left: 13px;
}
.report-btn-wrapper {
  margin: 10px;

  .export-btn {
    padding: 0px 9px;
    // background-color: $orange;

    button {
      border-radius: 5px;
      width: 176px;
      height: 50px;
      background-color: $orange;
      border: 0px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);
      color: $white;
      @include typography("medium", 14px);
      text-align: left;
      padding: 0px 6px;
    }

    input {
      opacity: 0;
      position: absolute;
      top: 100px;
      left: 0;
      height: 50px;
      width: 100%;
      cursor: pointer;
    }
  }
}
.skill-heading-button-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  h2 {
    @include typography("semi-bold", 20px);
    color: $greyTitle;
    margin: 0px;
  }

  .add-skillcoin-btn {
    background-color: $orange;
    color: $white;
    border-radius: 10px;
    @include typography("medium", 12px);
    height: 42px;
    width: 131px;
  }
}

.form-check-input {
  width: 1.2em;
  height: 1.2em;
}
.hyperlink {
  color: $paginationActive;
  text-decoration: underline;
  cursor: pointer;
}
.hyperlink:hover {
  color: $tableHeaderBg;
}
.rotate-icon {
  transform: rotate(90deg);
}

.employee-settled-btn {
  color: $updatedStatus;
}
.filter-wrapper {
  display: flex;
  justify-content: space-between;

  .skill-searchbar-wrapper {
    width: 78%;
    margin-top: 10px;
  }

  .skill-filter-container {
    width: 20%;

    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      background: transparent;
      background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
      background-repeat: no-repeat;
      background-position-x: 100%;
      background-position-y: 50%;
      border: 1px solid $borderColor;
      border-radius: 10px;
      width: 100%;
      padding: 1rem;
      padding-right: 2rem;
      background-color: $white;
    }
  }
}
.skill-heading-section {
  .heading-label {
    @include typography("medium", 12px);
    line-height: 18px;
    color: $greyTitle;
    margin-bottom: 10px;
  }
}
.update-skill-section-parent {
  display: flex;
  justify-content: space-between;
  .update-left-section {
    display: flex;
    gap: 10px;
    .form-control {
      width: 304px;
      height: 40px;
    }
    .update-skillcoin-btn {
      border-radius: 10px;
      @include typography("medium", 12px);
      height: 40px;
      width: 146px;
      color: $onHold;
      border: 1px solid $onHold;
      background-color: $white;
    }

    .update-disable-skillcoin-btn {
      border-radius: 10px;
      @include typography("medium", 12px);
      height: 40px;
      width: 146px;
      border: 1px solid $orangeCrossBg;
      background-color: $white;
      @include typography("medium", 12px);
      cursor: none;
      pointer-events: none;
      color: $orangeCrossBg;
    }
  }
  .update-right-section {
    display: flex;
    gap: 10px;
    .update-skillcoin-btn {
      border-radius: 10px;
      @include typography("medium", 12px);
      height: 40px;
      width: 113px;
      color: $onHold;
      border: 1px solid $onHold;
      background-color: $white;
    }
    .bulk-disable-btn {
      border-radius: 10px;
      @include typography("medium", 12px);
      height: 40px;
      width: 113px;
      cursor: none;
      pointer-events: none;
      color: $orangeCrossBg;
      border: 1px solid $orangeCrossBg;
      background-color: $white;
    }
  }
}
.edit-section {
  height: 24px;
  width: 40px;
  margin-left: 0px;
  cursor: pointer;
  border: 1px solid $approvedStatus;
  background-color: $white;
  border-radius: 5px;
  padding-top: 2px;
  .edit-skil-icon {
    cursor: pointer;
  }
}
.disable-edit-section {
  height: 24px;
  width: 40px;
  margin-left: 0px;
  cursor: none;
  pointer-events: none;
  border: 1px solid $lightblueDisable;
  background-color: $white;
  border-radius: 5px;
  padding-top: 2px;
  .disable-skil-icon {
    cursor: pointer;
  }
}
.add-section {
  height: 24px;
  width: 40px;
  margin-left: 0px;
  cursor: pointer;
  border: 1px solid $buttonBlue;
  background-color: $white;
  border-radius: 5px;
  .add-icon {
    padding-top: 4px;
    cursor: pointer;
  }
}
.disable-add-section {
  height: 24px;
  width: 40px;
  margin-left: 0px;
  cursor: none;
  pointer-events: none;
  border: 1px solid $lightblueDisable;
  background-color: $white;
  border-radius: 5px;
  .disable-icon {
    padding-top: 4px;
    cursor: none;
  }
}
// .table-body-row td {
//   border: 0px solid $white !important;
//   margin-left: 15px;
//   margin-top: 10px;
// }
.gap {
  gap: 10px;
}
// a {
//   color: $buttonBlue;
//   font-size: 18px;
// }

table thead:first-child {
  svg {
    display: none;
  }
}
.last-child {
  border: 1px solid $white !important;
  margin-top: 10px;
}

.reporticons {
  width: 25px;
  height: 25px;
  margin-top: 0px;
  margin-right: 10px;
}

.parent-section {
  display: flex;
  justify-content: space-between;
  .left-section {
    margin-top: 25px;
    .back-btn {
      font-size: 12px;
      text-decoration: none;
      color: $onHold;
    }
  }
  .right-section {
    display: flex;
    width: 57%;
    gap: 10px;
    flex-direction: row;
    .right-input {
      width: 304px;
      .form-control {
        height: 40px;
      }
    }
    .right-input2 {
      width: 304px;
      .form-control {
        height: 40px;
      }
    }
    .last-section {
      margin-top: 30px;
      .last-btn {
        width: 130px;
        height: 40px;
        border-radius: 10px;
        @include typography("medium", 12px);
        color: $onHold;
        border: 1px solid $onHold;
        background-color: $white;
      }
    }
  }
}
.report-section {
  display: flex;
  width: 100%;
  margin-top: 30px;
  flex-direction: row;
  gap: 10%;
  .cards-div {
    width: 263px;
    height: 90px;
    background-color: $white;
    border-radius: 10px;
    justify-content: center;
    padding-top: 10px;
    margin-bottom: 20px;
    display: flex;
    .reporticons {
      width: 40px;
      height: 40px;
      margin-top: 10px;
    }
    .heading-number {
      @include typography("semi-bold", 24px);
      color: $greyTitle;
      line-height: 36px;
      margin-left: 10px;
    }
  }
}
.chart-section {
  width: 100%;
  background-color: $white;
  margin-top: 20px;
  .chart-heading {
    padding: 10px 0px 0px 10px;
    @include typography("normal", 14px);
  }
  // .pie-heading{

  // }
}

.bulk-disable-btn {
  border-radius: 10px;
  @include typography("medium", 12px);
  cursor: none;
  pointer-events: none;
  color: $orangeCrossBg;
  border: 1px solid $orangeCrossBg;
  background-color: $white;
}

.pieChart {
  width: 100%;
  height: 400px;
}
.total-coins-required {
  font-size: 8px;
  color: $greyTitle;
}
.margin-left {
  margin-left: 0px;
}
