@import "../../scss/index.scss";

.submenu-hover-wrapper {
  left: 250px;
  position: fixed;
  background: $tableHeaderBg;
  margin-top: -45px;
  overflow: auto;
  max-height: -webkit-fill-available;
  width: 250px;
}

.mobile-submenu-hover-container {
  left: 55px;
  position: fixed;
  background: $tableHeaderBg;
  margin-top: -45px;
  overflow: auto;
  max-height: -webkit-fill-available;
  width: 250px;
}

.logo-wrapper {
  background-color: $white;
  display: flex;
  flex-direction: row;
  align-items: center;

  .spotlight-logo {
    width: 249px;
    height: 70px;
  }
}
