.dropdown-toggle {
  display: flex;
  flex-direction: row;
  justify-content: unset;
  font-size: 12px;
}
.dropdown-toggle::after {
  margin-top: 10px;
  position: absolute;
  right:5px;
}
