@import "../../scss/index.scss";

.filter-ijp-wrapper {
  display: flex;
  justify-content: space-between;

  .ijp-searchbar-wrapper {
    width: 75%;
  }

  .ijp-filter-container {
    width: 20%;

    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      background: transparent;
      background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
      background-repeat: no-repeat;
      background-position-x: 100%;
      background-position-y: 50%;
      border: 1px solid $borderColor;
      border-radius: 10px;
      width: 100%;
      padding: 1rem;
      padding-right: 2rem;
      background-color: $white;
    }
  }
}
.ijp-new-status {
  @include typography("medium", 12px);
  color: $newStatus;
}
.ijp-accepted-status {
  @include typography("medium", 12px);
  color: $approvedStatus;
}
.ijp-rejected-status {
  @include typography("medium", 12px);
  color: $inactiveText;
}
.ijp-kiv-status {
  @include typography("medium", 12px);
  color: $drafttext;
}
.ijp-heading-button-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  h2 {
    @include typography("semi-bold", 20px);
    color: $greyTitle;
    margin: 0px;
  }
}
.whyInterestedText {
  @include typography("regular", 14px);
  color: $lightGreyTitle;
}
.value-card {
  border: 1px solid $borderColor;
  border-radius: 10px;
  box-shadow: $boxShadowIjp 0px 3px 6px, $boxShadowIjp2 0px 3px 6px;
  .value-text {
    @include typography("medium", 14px);
    color: $tableBodyTitle;
  }
}
.view-file {
  cursor: pointer;
}
.export-report-wrapper-ijp-post-a-job {
  z-index: 1;
  // width: 10%;
  display: flex;
  position: relative;

  img {
    cursor: pointer;
  }

  .report-btn-wrapper-ijp {
    right: 0px;
    position: absolute;
    top: 40px;

    .export-btn-ijp {
      padding: 0px 9px;
      background-color: $orange;

      button {
        width: 176px;
        height: 50px;
        background-color: $orange;
        border: 0px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.3);
        color: $white;
        @include typography("medium", 14px);
        text-align: left;
        padding: 0px 6px;
      }

      input {
        opacity: 0;
        position: absolute;
        // top: 100px;
        left: 0;
        height: 50px;
        width: 100%;
        cursor: pointer;
      }
    }
  }
}
