@import "../../scss/index.scss";

.heading-button-wrapper {
  display: flex;
  justify-content: space-between;
  h2 {
    @include typography("semi-bold", 20px);
    color: $greyTitle;
  }
  .align-resourcemgnt-btns {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    .resourcemgnt-types-btn {
      background-color: $white;
      color: $buttonBlue;
      border-radius: 10px;
      border: 1px $buttonBlue solid;
      @include typography("medium", 12px);
      height: 42px;
      width: 165px;
    }
    .resourcemgnt-types-btn:hover {
      background-color: $buttonBlue;
      color: $white;
      border-radius: 10px;
      border: 1px $buttonBlue solid;
      @include typography("medium", 12px);
      height: 42px;
      width: 165px;
    }
  }
}
.buttons-row {
  margin-top: 25px;
}
.add-employee-btn {
  background-color: $orange;
  color: $white;
  border-radius: 10px;
  @include typography("medium", 12px);
  height: 42px;
  width: 131px;
}
.projectRepoData {
  text-align: center;
  font-size: 11px;
}
.csvLink {
  text-decoration: none;
  color: $white;
}

.filter-wrapper {
  display: flex;
  justify-content: space-between;
  .searchbar-wrapper {
    width: 75%;
  }

  .filter-container {
    width: 20%;
    height: 100%;
    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      background: transparent;
      background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
      background-repeat: no-repeat;
      background-position-x: 100%;
      background-position-y: 50%;
      border: 1px solid $borderColor;
      border-radius: 10px;
      width: 100%;
      padding: 1rem;
      padding-right: 2rem;
      background-color: $white;
    }
  }
  .add-projectRepo-btn {
    background-color: $orange;
    color: $white;
    border-radius: 10px;
    @include typography("medium", 12px);
    height: 100%;
    width: 130px;
  }
  .export-report-wrapper {
    display: flex;
    position: relative;
    align-items: center;

    img {
      cursor: pointer;
    }

    .report-btn-wrapper {
      right: 0px;
      position: absolute;
      top: 40px;
      .export-btn {
        padding: 0px 9px;
        background-color: $orange;
        text-decoration: none;
        button {
          width: 176px;
          height: 50px;
          background-color: $orange;
          border: 0px;
          border-bottom: 1px solid rgba(255, 255, 255, 0.3);
          color: $white;
          @include typography("medium", 14px);
          text-align: left;
          padding: 0px 6px;
        }
        input {
          opacity: 0;
          position: absolute;
          top: 100px;
          left: 0;
          height: 50px;
          width: 100%;
          cursor: pointer;
        }
      }
    }
  }
}
.projectRepo-status-btn {
  height: 25px;
  width: 60px;
  padding: 2px 4px;
  border-radius: 6px;
  font-size: 12px;
  font-weight: 400;
  cursor: default !important;
}
.projectRepo-new-btn {
  color: $newStatus;
}

.projectRepo-approved-btn {
  color: $approvedStatus;
}

.projectRepo-rejected-btn {
  color: $rejectedStatus;
}
.projectRepo-updated-btn {
  color: $updatedStatus;
}
.projectRepo-ongoing-btn {
  color: $onGoing;
}

.projectRepo-onHold-btn {
  color: $onHold;
}

.projectRepo-closed-btn {
  color: $closed;
}

.projectRepo-delivered-btn {
  color: $delivered;
}
.edit-icon {
  height: 12px;
  width: 12px;
  margin-left: 6px;
  cursor: pointer;
}
.table-responsive {
  overflow-y: hidden;
}
// table th:last-child {
//   width: 100px;
// }
.active-btn {
  background-color: $buttonBlue !important;
  color: $white !important;
  border-radius: 10px;
  border: 1px $buttonBlue solid;
  @include typography("medium", 12px);
  height: 42px;
  width: 165px;
}
.tabs-container {
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px solid $borderColor;
  margin-top: 20px;
}
.profile-container {
  // padding: 35px 45px;
  margin-top: 20px;
  h5 {
    @include typography("semi-bold", 14px);
    color: $nameHeader;
  }
}

.wip-btn {
  background-color: $lightSeaGreen;
}
.initiate-btn {
  background-color: $fieryRose;
}
.delivered-btn {
  background-color: $meatBrown;
}
.closed-btn {
  background-color: $philippineGrey;
}
.open-btn {
  background-color: $ceruleanBlue;
}

//Resource management

.status-btn {
  padding: 2px 4px;
  font-size: 11px;
  width: 70px;
}
.wip-btn {
  background-color: $lightSeaGreen !important;
  border: none !important;
  &:hover {
    background-color: $celadonGreen !important;
  }
}
.delivery-btn {
  background-color: $deepChampagne !important;
  border: none !important;
  color: $philippineGold !important;
  &:hover {
    background-color: $caramel !important;
  }
}
.closed-btn {
  background-color: $philippineGrey !important;
  border: none !important;
  &:hover {
    background-color: $gray !important;
  }
}
.onhold-btn {
  background-color: $lightCobalBlue !important;
  border: none !important;
  &:hover {
    background-color: $cornflowerBlue !important;
  }
}

//Bench Management status
.status-black {
  color: $black;
}
.status-grey {
  color: $gray2;
}
.status-red {
  color: $red;
}
.status-yellow {
  color: $mangoOrange;
}
.status-green {
  color: green;
}
.status-nothing {
  color: none;
}

.show-entries-wrapper {
  font-size: 14px;
  font-family: poppins;

  .select-div {
    height: 25px;
    width: 50px;
    margin: 6px 6px;
    border: none;
    border-radius: 2px;
    outline: none;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  }
}
.status-click {
  cursor: pointer;
  text-decoration: none;
}
.allRecords {
  margin-left: 15px;
  text-decoration: none;
}
.project-repo-anchor-tag {
  @include typography("regular", 12px);
  color: $buttonBlue;
  word-wrap: break-word; 
  white-space: normal; 
  overflow-wrap: break-word; 
  max-width: 100px;

}
.action-col {
  width: 50px;
}
