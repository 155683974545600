@import "../../../scss/index.scss";

.employee-skill-heading-button-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;

  .left-section {
    margin-top: 25px;
    .back-btn {
      font-size: 12px;
      text-decoration: none;
      color: $onHold;
    }
  }
}

.skill-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  h2 {
    @include typography("semi-bold", 20px);
    color: $greyTitle;
    margin: 0px;
  }
}

.emp-section {
  margin-bottom: 40px;
  .emp-label {
    @include typography("semi-bold", 20px);
    color: $onHold;
  }
}
.margin-left {
  margin-left: 0px;
}

.border-none {
  border: none;
}
