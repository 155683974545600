@import "../../scss/index.scss";

.skill-filter-container {
    width: 35%;
    display: flex;
    gap: 10px;
    margin-top: 10px;
    @include typography("medium", 14px);
    color: $tableBodyTitle;
    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      background: transparent;
      background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
      background-repeat: no-repeat;
      background-position-x: 100%;
      background-position-y: 50%;
      border: 1px solid $borderColor;
      border-radius: 10px;
      width: 100%;
      padding: 0.5rem;
      padding-right: 2rem;
      background-color: $white;
    }
  }