.subsidebar-container {
    margin-left: 60px;
    font-family: "Poppins";
    font-size: 13px;
}

.potential-card-container{
    padding: 10px;
    background-color: #FFF9F4;
    display: flex;
    flex-direction: row;
    width: 200px;
    height: 70px;
}
.score{
    color: #2D4576;
    font-size: 22px;
}