@import "../../../scss/index.scss";

.edit-button {
  border: 1px solid $orange;
  border-radius: 18px;
  color: $orange;
  @include typography("regular", 12px);
  width: auto;
  height: 30px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  background-color: $white;
}
