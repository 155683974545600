@import "../../../scss/index.scss";

.module-details-card {
  border-radius: 0px;
  border: none;
  box-shadow: none;
  overflow-x: hidden;
  .module-back-button {
    cursor: pointer;
    .backIcon {
      object-fit: contain;
      width: 25px;
      height: 25px;
    }
    .back-text {
      color: $orange;
      @include typography("medium", 16px);
    }
  }
  .module-heading {
    @include typography("normal", 20px);
    color: $projectHeading;
  }
  .module-name {
    @include typography("normal", 32px);
    color: $projectName;
  }
  .project-details {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  .module-details-label {
    color: $projectHeading;
    @include typography(normal, 14px);
  }
  .module-details-values {
    color: $projectName;
    @include typography(normal, 14px);
  }
  .module-edit-button {
    color: $newStatus;
    border-radius: 18px;
    width: 100px;
    border: 1px solid $newStatus;
  }
  .module-delete-button {
    color: $orange;
    border-radius: 18px;
    width: 100px;
    border: 1px solid $orange;
  }
  .editdelete-icon {
    width: 12px;
    height: 12px;
    object-fit: contain;
  }
  .module-edit-button:hover {
    color: $white;
    border-radius: 18px;
    width: 100px;
    background-color: $newStatus;
    border: 1px solid $newStatus;
  }
  .module-delete-button:hover {
    color: $white;
    border-radius: 18px;
    width: 100px;
    background-color: $orange;
    border: 1px solid $orange;
  }
  .content-heading {
    @include typography("medium", 14px);
  }
  .card {
    border: none;
    box-shadow: none;
    .content {
      @include typography("regular", 14px);
      color: $tableBodyTitle;
    }
  }
}
