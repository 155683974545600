@import "../../scss/index.scss";


.ReasonRejectStyle {
  background-color: $lightWhite !important;
  width: 24rem !important;
  height: 10rem !important;
}
.modalFontSize{
  font-size: 20px;
}



.main-container {
  padding: 1px;
  height: auto;
  width: 100vw;
  padding-right: 30px;
  padding-left: 278px;
  .header-container {
  
      position: relative;
      z-index: 0;

    height: auto;
    padding: 8px;
    margin-bottom: 10px;
    @include typography("semi-bold", 16px);
    color: $buttonDisable;
    margin-top: 60px;
  }
}

.padding-left {
  padding-left: 84px;;
}
