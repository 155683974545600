@import "../../../scss/index.scss";

.financial-container {
  // padding: 20px 50px;
  .main-title {
    color: $greyTitle;
    @include typography("medium", 14px);
    line-height: normal;
  }
  .card-div-container {
    // padding: 10px 0px;
    .card-title {
      color: $greyTitle;
      @include typography("medium", 14px);
      line-height: normal;
    }
    .card-content-container {
      display: flex;
      width: auto;
      .card-content {
        width: 33%;
        padding: 10px 0px;
        .card-content-title {
          color: $lightGreyTitle;
          @include typography("regular", 14px);
          line-height: normal;
        }
        .card-content-data {
          color: $greyTitle;
          @include typography("regular", 14px);
          line-height: normal;
        }
      }
    }
  }

  .bank-card-div-container {
    // padding: 10px 0px;
    .card-title {
      color: $greyTitle;
      @include typography("medium", 14px);
      line-height: normal;
    }
    .bank-card-content-container {
      display: flex;
      width: auto;
      flex-wrap: wrap;
      .card-content {
        width: 33%;
        padding: 10px 0px;
        .card-content-title {
          color: $lightGreyTitle;
          @include typography("regular", 14px);
          line-height: normal;
        }
        .card-content-data {
          color: $greyTitle;
          @include typography("regular", 14px);
          line-height: normal;
        }
      }
    }
  }
}
