@import "../../scss/index.scss";

.searchbar {
  width: 100%;
  height: 100%;
  position: relative;

  input {
    width: 100%;
    height: 100%;
    padding: 12px 12px 12px 16px;
    border-radius: 10px;
    border: 1px solid $borderColor;
    background-color: $white;
    outline: none;
    @include typography("medium", 14px);
    color: $tableBodyTitle;
    // box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
  }

    .btn {
      display: flex;
      // min-width: 65px;
      height: 35px;
      width: 35px;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;
      gap: 0 0.5rem;
      position: absolute;
      top: 8px;
      right: 8px;
      background: ghostwhite;
      // border: transparent;
      // border-color: #c6c5c9;
      // box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      //   0 4px 6px -2px rgba(0, 0, 0, 0.05);
      border-radius: 50%;
      padding: 3px 6px;
      outline: 0;
      // transition: 0.2s ease-in-out all;
      &:hover {
        background-color: rgb(240, 240, 252);
      }
    }
  

  .search-icon {
     position: absolute;
     top: 8px;
     right: 9px;
     cursor: pointer;
  }
}
.disabled-searchbar {
  input {
    background-color: $light-gray;
  }
}
