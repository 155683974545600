@import "../../scss/index.scss";

.filter-wrap {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.filter-label{
    padding: 4px 8px 4px 8px;
    margin: 10px 10px 0px 0px;
    background-color: $white;
    color: rgba(28, 27, 31, 0.7);
    width: auto;
    height: auto;
    border: none;
    @include typography("medium", 12px);
    align-items: center;
    border-radius: 5px;
}
.cursor {
  cursor: pointer;
  padding: 0px 5px 3px 5px;
}
.filter-by {
  @include typography("normal", 12px);
  margin-top: 12px;
}
.filter-by{
    @include typography("medium", 12px);
    margin-top: 15px;
}
