@import "../../../scss/index.scss";

.personal__info-container {
  display: flex;
  justify-content: space-between;

  .view-resume {
    @include typography("regular", 12px);
    color: $orange;
  }
  .personal__info-profile-details {
    display: flex;
    align-items: center;
    gap: 38px;

    img {
      height: 70px;
      width: 70px;
      border-radius: 50%;
    }

    p {
      @include typography("semi-bold", 16px);
      margin: 0px;
      color: $nameHeader;
    }

    .profile-position {
      @include typography("regular", 12px);
      margin-top: 6px;
      color: $lightGreyTitle;
    }
  }

  .download-wrapper {
    border: 1px solid $orange;
    border-radius: 8px;
    color: $orange;
    text-align: center;
    @include typography("regular", 10px);
    line-height: 15px;
    padding: 5px;
    position: relative;
    cursor: pointer;
    width: 200px;

    button {
      border: 0px;
      background-color: $white;
      color: $orange;
    }

    p {
      margin: 0px;
    }

    input {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
  }
}

.edit-icon-wrapper {
  display: flex;
  justify-content: space-between;

  .profile-details {
    width: 30%;
    p {
      @include typography("regular", 14px);
      color: $greyTitle;
    }

    span {
      @include typography("regular", 14px);
      color: $lightGreyTitle;
    }
  }
}

.header-img {
  height: 150px;
  width: 200px;
}

.profilePic {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  margin-right: 20px;
}

.skillminelogoSection {
  display: flex;
  justify-content: center;
  height: 250px;
  margin-top: -15%;
  padding-bottom: 10px;
  .skillmine_Logo {
    // height:100px;
  }
}
.imageDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 160px;
  margin-top: -10%;
}
.reminder-checker-outline-btn {
  background-color: $white;
  color: $orange;
  border-radius: 10px;
  border: 1px solid $orange;
  @include typography("medium", 12px);
  height: 42px;
  width: 130px;
  &:hover {
    background-color: $white;
  }
}
.reminderSection {
  width: 400px;
}
.reminder-cancel-btn {
  border: 2px solid $orange;
  height: 42px;
  width: 150px;
  color: $orange;
}

.reminder-cancel-btn:hover {
  border: 2px solid $orange !important;
  color: $orange !important;
}

.reminder-add-submit-button {
  height: 42px;
  width: 150px;
  color: $white;
  background-color: $tableHeaderBg;
}

.reminder-add-submit-button:hover {
  color: $white !important;
  background-color: $tableHeaderBg !important ;
}

.profile-width{
  width: 400px !important;
  display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -1px;
}
.modal-profile-section{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;

}
.profileError{
  display: flex;
  justify-content: center;
  align-items: center;
  color: $red;
  font-size: 12px;
}
