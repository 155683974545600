.loading-container-modal {
    .modal-dialog {
        height: 100vh !important;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100vh !important;
        .modal-title {
            height: 90px;
            // width: 500px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 7px;
            text-transform: capitalize;
            font-size: 13px;
            .dots {
                letter-spacing: 2px;
                margin-left: 2px;                
            }

        .loadingText{
            margin-left: 20px;
        }
            @media screen and (max-width:767px) {
                svg {
                    height: 70px;
                    width: 70px;
                }
            }
        }
    }
}