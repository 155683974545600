@import "../../scss/index.scss";
.button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: auto;
  background-color: #28BCB9;
  font-size: 12px;
  color: #fff;
  border: 0;
  border-radius: 6px;
  margin-left: 5px;

  .button-icon {
    margin-right: 6px;
    height: 16px;
    width: 16px;
  }
}
.button:hover {
  background-color: $orange;
}
.back-button {
  cursor: pointer;
  .backIcon {
    object-fit: contain;
    width: 25px;
    height: 25px;
  }
  .back-text {
    color: $orange;
    @include typography("medium", 16px);
  }
}
