@import "../../../scss/index.scss";

.personal__info-container {
  display: flex;
  justify-content: space-between;

  .view-resume {
    @include typography("regular", 12px);
    color: $orange;
  }
  .personal__info-profile-details {
    display: flex;
    align-items: center;
    gap: 38px;

    img {
      height: 70px;
      width: 70px;
      border-radius: 50%;
    }

    p {
      @include typography("semi-bold", 16px);
      margin: 0px;
      color: $nameHeader;
    }

    .profile-position {
      @include typography("regular", 12px);
      margin-top: 6px;
      color: $lightGreyTitle;
    }
  }

  .upload-wrapper {
    border: 1px solid $orange;
    border-radius: 8px;
    color: $orange;
    text-align: center;
    @include typography("regular", 10px);
    line-height: 15px;
    padding: 5px;
    position: relative;

    button {
      border: 0px;
      background-color: $white;
      color: $orange;
    }

    p {
      margin: 0px;
    }

    input {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      cursor: pointer;
    }
  }
}

.edit-icon-wrapper {
  display: flex;
  justify-content: space-between;

  .profile-details {
    width: 30%;
    p {
      @include typography("regular", 14px);
      color: $greyTitle;
    }

    span {
      @include typography("regular", 14px);
      color: $lightGreyTitle;
    }
  }
}
.profileBodysection{

  display: flex;
  justify-content: center;
}
.editButtonSection{
  display: flex;
  justify-content: flex-end;
}
.skillmineLogo {
  height: 350px;
}

.profile-edit-btn {
  background-color: $orange;
  color: $white;
  border-radius: 20px;
  @include typography("medium", 16px);
  height: 35px;
  width: 80px;
  border: none;
}


.personalinfo-resume-section{
  display: flex;
  justify-content: center;
  align-items: center;
  gap:30px;

  .resume-button {
    height: 50px;
    width: 200px;
    color: $white;
    background-color: $orange;
    text-wrap: wrap;
    border-radius: 5px;
    border:none;
  }
  
  .resume-button:hover {
      color: $white !important;
      background-color:  $orange !important;
    }
}
.modalResumeSection{
  display: flex;
  justify-content: center;
  align-items: center;
  gap:30px;
  margin:30px;
  .modal-resume-button {
    height: 50px;
    width: 150px;
    color: $orange;
    background-color: $white;
    text-wrap: wrap;
    border-radius: 5px;
    border: 1px solid $orange;
  }
  
  .modal-resume-button:hover {
      color: $white !important;
      background-color:  $orange !important;
    }
}
.language-btn {
  background-color: $orange;
  color: $white;
  border-radius: 20px;
  @include typography("medium", 16px);
  height: 35px;
  width: 80px;
  border: none;
}
.language-btn:hover {
  color: $white !important;
  background-color:  $orange !important;
}
.language-section {
  display: flex;
  gap:30px;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.expertise-select{
  width: 100%;
  height: 35px;
  border: 1px solid $selectDropdoown;
  background-color: $white;
  border-radius: 5px;
  @include typography("medium", 12px);
}
.column {
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 20px;
}

.proficiency-section {
  display: flex;
}
.proficiencyLabel{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.sub-column {
  display: flex;
  gap:10px;
  margin-right: 10px;
}
.delete-language{
  margin-top: 4%;
}
.submit-language{
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.labelCheckBox{
  height: 20px;
  width: 20px;
}
.marginbottom{
  margin-bottom: 20px;
}