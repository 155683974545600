@import "../../../scss/index.scss";

.be-a-coach-container {
  .rotate-icon {
    transform: rotate(270deg);
  }
  .be-a-coach-searchbar-wrapper {
    display: flex;
    justify-content: space-between;
    gap: 25px;
    margin-top: 25px;

    .searchbar-wrapper {
      width: 80%;
    }

    .be-a-coach-filter-container {
      width: 20%;

      select {
        -webkit-appearance: none;
        -moz-appearance: none;
        background: transparent;
        background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
        background-repeat: no-repeat;
        background-position-x: 100%;
        background-position-y: 50%;
        border: 1px solid $borderColor;
        border-radius: 10px;
        width: 100%;
        padding: 1rem;
        padding-right: 2rem;
        background-color: $white;
        @include typography("regular", 12px);
      }
    }
  }

  table {
    background-color: $white;
    thead {
      background-color: $tableHeaderBg;
      height: 45px;
      color: $white;
      vertical-align: middle;
      text-align: center;

      td {
        border: 1px solid;
      }
    }

    td {
      border: 1px solid $borderColor;
      vertical-align: middle;
      height: 41px;
    }

    .blue-tick-btn {
      border: 1px solid $tableHeaderBg;
      background-color: $blueTickBg;
      border-radius: 5px;
      display: flex;
      @include typography("regular", 9px);
      margin-right: 8px;
      width: 40px;
      justify-content: center;
      align-items: center;
    }
    .blue-tick-btn:disabled,
    .blue-tick-btn[disabled] {
      border: 1px solid $buttonDisable;
      background-color: $buttonLightBule;
      color: $black;
    }
    .orange-cross-btn {
      border: 1px solid $orange;
      background-color: $orangeCrossBg;
      border-radius: 5px;
      display: flex;
      @include typography("regular", 9px);
      width: 40px;
      justify-content: center;
      align-items: center;
    }
    .orange-cross-btn:disabled,
    .orange-cross-btn[disabled] {
      border: 1px solid $onHold;
      background-color: $buttonOrange;
      color: $black;
    }

    .approved-btn {
      color: $approvedStatus;
    }
  }
}

.selectedDay {
  border: 1px solid gray;
  border-radius: 50%;
  padding: 1px;
  margin: 4px;
  width: 10%;
}
.selectedDay-color {
  color: white;
  background-color: blue;
}
.unSelectedDay-color {
  color: black;
  background-color: white;
}
