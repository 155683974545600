@import "../../../scss/index.scss";
#pdf-content {
  margin: 20px;
}

.coach-details-card-container {
  background: $white;
  padding: 30px;
  display: flex;
  justify-content: space-between;

  .coach-details-card__first-wrapper {
    a {
      color: $orange;
      text-decoration: none;
      span {
        margin-left: 13px;
      }
    }

    .coach-name-title {
      color: $grey;
      @include typography("regular", 20px);
      line-height: 40px;
      margin-top: 16px;
    }

    .coach-name-data {
      color: $black;
      @include typography("regular", 32px);
      line-height: 40px;
    }
  }

  .coach-details-card__second-wrapper {
    display: flex;
    justify-content: space-between;
    gap: 20%;
    width: 40%;

    .coach-details-wrapper {
      .coach-title-wrapper {
        display: flex;
        gap: 15px;

        span {
          color: $grey;
          @include typography("regular", 14px);
          line-height: 24px;
          width: 100%;
        }

        p {
          color: $black;
          @include typography("regular", 14px);
          line-height: 24px;
          margin: 0px;
          text-align: left;
        }
      }
    }
  }
}

.coach-report-tabs-container {
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px solid $borderColor;
  gap: 40px;
  padding-left: 28px;
  margin-top: 20px;
}

table {
  background-color: $white;

  tr {
    @include typography("regular", 12px);
  }

  thead {
    background-color: $tableHeaderBg;
    height: 45px;
    color: $white;
    vertical-align: middle;
    text-align: center;

    td {
      border: 1px solid;
      @include typography("regular", 12px);
    }
  }

  td {
    border: 1px solid $borderColor;
    vertical-align: middle;
    height: 41px;
  }
}

.report-pdf-link {
  text-decoration: none;
  color: $orange;
  span {
    margin-left: 13px;
  }
}

.report-pdf-link:hover {
  color: $orange;
}
