@import "../../../../scss/index.scss";
.resource-action-btn{
  cursor: pointer;
  display: flex;
  gap:5px;
}
.remarks-container {
  width: 800px;
  .remarks-container1 {
    display: flex;
    width: 100%;
    padding: 10px;
    .remarks-data {
      width: 33%;
      display: flex;
      .remarks-title {
        color: $tableBodyTitle;
        line-height: normal;
        @include typography("normal", 16px);
      }
      .remarks-content {
        color: $greyTitle;
        @include typography("normal", 16px);
        line-height: normal;
        padding-left: 10px;
      }
    }
  }
  .remarks-container2 {
    display: flex;
    width: 100%;
    padding: 10px;

    .remarks-data {
      width: 33%;
      display: flex;

      .remarks-title {
        color: $tableBodyTitle;
        line-height: normal;
        @include typography("normal", 16px);
      }
      .remarks-content {
        color: $greyTitle;
        @include typography("normal", 16px);
        line-height: normal;
        padding-left: 10px;
      }
    }
  }
  .remarks-container3 {
    display: flex;
    width: 100%;
    padding: 10px;
    .remarks-data {
      width: 100%;
      display: flex;
      .remarks-title {
        color: $tableBodyTitle;
        line-height: normal;
        @include typography("normal", 16px);
      }
      .remarks-content {
        color: $greyTitle;
        @include typography("normal", 16px);
        line-height: normal;
        padding-left: 10px;
      }
    }
  }
}
.btn-container {
  text-align: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
  .close-btn {
    justify-content: center !important;
    background-color: $white !important;
    border-radius: 10px !important;
    border: 2px solid $orange !important;
    width: 300px;
    .button {
      background-color: $white !important;
      color: $orange !important;
      @include typography("medium", 16px);
    }
  }
}
.expanded-row {
  background-color: $white;
}

.expand-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}
