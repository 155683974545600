//skill

.right__container {
  width: 100%;
}
.employeedata {
  text-align: center;
  font-size: 11px;
}

.employeedb {
  background-color: #3C65B0;
  margin-top: 180px;
  color: white;
  text-align: center;
}
.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.downloadicon {
  color: #3371eb;
}

.workmodal {
  display: flex;
  margin-bottom: 30px;
}
// .btn-next-pre {
//   position: absolute;
//   bottom: 5%;
//   font-size: 12px;
//   overflow: auto;
//   background-color: #3C65B0 !important;
// }
// .btn-skip {
//   position: absolute;
//   bottom: 5%;
//   left: 92%;
//   font-size: 12px;
//   overflow: auto;
// }
.btn-next-pre {
  position: sticky;
  bottom: 5px;
  font-size: 12px;
  overflow: auto;
  background-color: #3C65B0 !important;
  margin-top: 70px;
}

.btn-skip {
  position: sticky;
  bottom: 5px;
  left: 92%;
  font-size: 12px;
  overflow: auto;
  margin-top: 70px;
}

