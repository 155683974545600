$white: #ffffff;
$black: #000000;
$borderColor: rgba(0, 0, 0, 0.1);
$orange: #e56d39;
$greyTitle: #1c1b1f;
$lightGreyTitle: rgba(28, 27, 31, 0.4);
$tableBodyTitle: rgba(28, 27, 31, 0.7);
$tableHeaderBg: #1853a2;
$nameHeader: rgba(28, 27, 31, 1);
$paginationActive: #528ef7;
$activeText: #198754;
$inactiveText: #dc3545;
$newText: #5782f5;
$drafttext: #f9c964;
$buttonBlue: #1853a2;
$newStatus: #4c9ae3;
$approvedStatus: #0fcf5c;
$rejectedStatus: #e51717;
$updatedStatus: #cfc93a;
$onGoing: #0fcf5c;
$onHold: #e56d39;
$closed: #e51717;
$delivered: #a644d4;
$projectHeading: rgba(28, 27, 31, 0.5);
$projectName: rgba(28, 27, 31, 1);
$viewFile: rgba(24, 83, 162, 1);
$grey: rgba(28, 27, 31, 0.5);
$offWhite: rgba(255, 255, 255, 0.9);
$lightSeaGreen: #28bca3;
$fieryRose: #ef5467;
$meatBrown: #eaae36;
$philippineGrey: #929292;
$ceruleanBlue: #3159c6;
$celadonGreen: #1c8d7a;
$deepChampagne: #f3daa8;
$caramel: #f1d395;
$gray: #818181;
$philippineGold: #b17908;
$lightCobalBlue: #86a3f1;
$cornflowerBlue: #6a8ce9;
$gray2: #808080;
$red: #ff0000;
$mangoOrange: rgba(255, 166, 0, 0.856);
$grey: rgba(28, 27, 31, 0.5);
$buttonLightBule: #a5c6f366;
$buttonOrange: #ffe7dc;
$buttonDisable: #1853a266;
$rejectBackground: #ffc2a866;
$blueTickBg: #a5c6f3;
$orangeCrossBg: #ffc2a8;
$boxShadow: rgba(0, 0, 0, 0.5);
$lightgreen: #bff1bf;
$greenDisable: #49dd98;
$lightgreenDisable: #bef3da;
$blueBackground: #dbe5f3;
$blueDisable: #99bae5;
$lightblueDisable: #dfe8f3;
//Skill links
$ratingWarning: rgba(122, 118, 14, 1);
$warningBg: rgba(255, 252, 186, 0.6);
$approvedBtnBorder: rgba(25, 135, 84, 1);
$approvedBg: rgba(92, 239, 151, 0.4);
$updatedBtnBorder: rgba(229, 109, 57, 1);
$updatedBg: rgba(245, 146, 34, 0.3);
$rejetedBtnBorder: rgba(220, 53, 69, 1);
$rejectedBg: rgba(239, 65, 55, 0.2);
$draftBtnBorder: rgba(24, 83, 162, 1);
$draftBg: rgba(165, 198, 243, 0.4);
$light-gray: #EAE9E9;
$viewJobDisable: rgba(157, 157, 157, 1);
$boxShadowIjp: rgba(0, 0, 0, 0.16);
$boxShadowIjp2: rgba(0, 0, 0, 0.23);
$internalTraining: rgba(15, 207, 92, 0.7);
$NewText: #39ace5;
$disabledInput: rgba(204, 204, 204, 0.4);
$selectDropdoown:#ced4da;
$lightWhite:#FFF9F4
