@import "../../../scss/index.scss";

.be-a-coach-container {
  .be-a-coach-searchbar-wrapper {
    display: flex;
    justify-content: space-between;
    gap: 25px;
    margin-top: 25px;

    .searchbar-wrapper {
      width: 80%;
    }

    .be-a-coach-filter-container {
      width: 20%;

      select {
        -webkit-appearance: none;
        -moz-appearance: none;
        background: transparent;
        background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
        background-repeat: no-repeat;
        background-position-x: 100%;
        background-position-y: 50%;
        border: 1px solid $borderColor;
        border-radius: 10px;
        width: 100%;
        padding: 1rem;
        padding-right: 2rem;
        background-color: $white;
        @include typography("regular", 12px);
      }
    }
  }

  table {
    background-color: $white;
    thead {
      background-color: $tableHeaderBg;
      height: 45px;
      color: $white;
      vertical-align: middle;
      text-align: center;

      td {
        border: 1px solid;
      }
    }

    td {
      border: 1px solid $borderColor;
      vertical-align: middle;
      height: 41px;
    }
  }
}

.total-coaching-reports-card-container {
  display: flex;
  gap: 30px;
  margin-top: 28px;

  .card-wrapper {
    border: 1px solid $borderColor;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    padding: 15px;
    padding-right: 45px;
    background-color: $white;

    .title-data-wrapper {
      display: flex;
      flex-direction: column;

      .data-title {
        @include typography("regular", 12px);
        color: $tableBodyTitle;
      }
      .data {
        @include typography("medium", 24px);
        color: $nameHeader;
      }
    }
  }
}
