@import "../../scss/index.scss";

.heading-button-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  h2 {
    @include typography("semi-bold", 20px);
    color: $greyTitle;
    margin: 0px;
  }

  .add-employee-btn {
    background-color: $orange;
    color: $white;
    border-radius: 10px;
    @include typography("medium", 12px);
    height: 42px;
    width: auto;
  }
}

.filter-wrapper {
  display: flex;
  justify-content: space-between;

  .emp-searchbar-wrapper {
    width: 75%;
  }

  .emp-filter-container {
    width: 20%;

    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      background: transparent;
      background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
      background-repeat: no-repeat;
      background-position-x: 100%;
      background-position-y: 50%;
      border: 1px solid $borderColor;
      border-radius: 10px;
      width: 100%;
      padding: 1rem;
      padding-right: 2rem;
      background-color: $white;
    }
  }

  .export-report-wrapper {
    // width: 10%;
    display: flex;
    position: relative;
    align-items: center;

    img {
      cursor: pointer;
    }

    .report-btn-wrapper {
      right: 0px;
      position: absolute;
      top: 40px;

      .export-btn {
        padding: 0px 9px;
        background-color: $orange;
        .width-btn {
          width: 220px !important;
        }
        button {
          width: 176px;
          height: 50px;
          background-color: $orange;
          border: 0px;
          border-bottom: 1px solid rgba(255, 255, 255, 0.3);
          color: $white;
          @include typography("medium", 14px);
          text-align: left;
          padding: 0px 6px;
        }

        input {
          opacity: 0;
          position: absolute;
          top: 100px;
          left: 0;
          height: 50px;
          width: 100%;
          cursor: pointer;
        }

        .empInput {
          opacity: 0;
          position: absolute;
          top: 150px !important;
          left: 0;
          height: 50px;
          width: 100%;
          cursor: pointer;
        }
      }
    }
  }
}

.show-entries-wrapper {
  display: flex;
  justify-content: space-between;

  .select-text {
    display: flex;
    gap: 15px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    gap: 15px;
    @include typography("medium", 14px);
    color: $lightGreyTitle;
  }

  // .select-div {
  //   height: 25px;
  //   width: 50px;
  //   margin: 6px 6px;
  //   border: none;
  //   border-radius: 2px;
  //   outline: none;
  //   box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  // }
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 50%;
    width: auto;
    border: 0;
    padding: 10px;
    padding-right: 2rem;
    background-color: $white;
  }
}

.employeedb-record-status-btn {
  height: 25px;
  width: 80px;
  border: none;
  padding: 2px 4px;
  font-size: 11px;
  color: #fff;
  font-family: poppins;
  border-radius: 6px;
  cursor: default !important;
}
.employeedb-status-btn {
  height: 25px;
  width: 60px;
  padding: 2px 4px;
  border-radius: 6px;
  font-size: 12px;
  font-weight: 400;
  cursor: default !important;
}

.employee-active-btn {
  color: $activeText;
}
.employee-inactive-btn {
  color: $inactiveText;
}
.employee-new-btn {
  color: $newText;
}
.employee-draft-btn {
  color: $drafttext;
}
.employee-resent-btn {
  color: $buttonBlue;
}
.employee-published-btn {
  color: $delivered;
}
.employee-republished-btn {
  color: $orange;
}

// .accept-btn {
//   background-color: #cbfaf2;
//   color: #1ba08a;
//   &:hover{
//     background-color:#aceee3 !important ;
//     color: #19b69c !important;
//   }
// }
// .reject-btn {
//   background-color: #f7c5cb !important;
//   color: #EF5467;
//   &:hover{
//     background-color:#f7b7bf!important ;
//     color: #EF5467 !important;
//   }
// }
// .reject-btn {
//   background-color: #ef5467;
// }
// .new-btn {
//   background-color: #0f5a4d;
// }
// .approve-btn {
//   background-color: #611f87;
// }
// .updated-btn {
//   background-color: #f1b44c;
// }
// .Updated-btn {
//   background-color: #c5f0f7 !important;
//   color: #0e4b3d;
//   &:hover{
//     background-color:#5be4e9!important ;
//     color: #0e4b3d !important;
//   }
// }
.edit-icon {
  height: 12px;
  width: 12px;
  margin-left: 6px;
  cursor: pointer;
}

.user-icon {
  height: 30px;
  // border-radius: 50px;
  clip-path: circle();
}
.modal-btn-wrapper {
  // width: 63%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  justify-content: center;
  gap: 25px;
}
.upload {
  position: absolute;
  left: 224px;
  top: 240px;
  height: 25px;
}
.employee-details {
  margin-top: 16px;
  scroll-margin: 100px;
}
.hyperlink {
  color: rgb(103, 103, 251);
  text-decoration: underline;
  cursor: pointer;
}
.hyperlink:hover {
  color: hsl(199, 98%, 18%);
}
.approve {
  background: rgb(8, 140, 19);
  color: rgb(240, 235, 235);
}
.reject {
  background: rgb(168, 33, 33);
  color: rgb(240, 235, 235);
}
.chart-card {
  // box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
  //   rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  width: 70%;
}
.chart-container {
  width: 100%;
  height: 388px;
}

.report-chart-container {
  background-color: $white;
  padding: 20px;
  padding-right: 30px;
  margin-top: 20px;

  h5 {
    color: $greyTitle;
    @include typography("regular", 14px);
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    background-image: url("../../assets/arrow_drop_down_orange.svg");
    background-repeat: no-repeat;
    background-position-x: 90%;
    background-position-y: 50%;
    border: 1px solid $orange;
    color: $orange;
    border-radius: 18px;
    width: 170px;
    height: 30px;
    padding: 2px 10px 2px 10px;
    background-color: $white;
    @include typography("regular", 12px);
  }
  select:focus {
    outline: none;
  }
}

.maker-checker-btn {
  background-color: $orange;
  color: $white;
  border-radius: 10px;
  @include typography("medium", 12px);
  height: 42px;
  width: 110px;
}
.maker-checker-outline-btn {
  background-color: $white;
  color: $orange;
  border-radius: 10px;
  border: 1px solid $orange;
  @include typography("medium", 12px);
  height: 42px;
  width: 110px;
  &:hover {
    background-color: $white;
  }
}
