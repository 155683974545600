@import "../../../scss/index.scss";
.resource-Approve-btn {
  border-radius: 10px;
  @include typography("medium", 12px);
  height: 40px;
  width: 113px;
  color: $onHold;
  border: 1px solid $onHold;
  background-color: $white;
  &:hover {
    color: $onHold;
    border: 1px solid $onHold;
    background-color: $white;
  }
}

.button-invoice {
  width: 90px;
  height: 30px;
}
.add-invoice {
  height: 30px;
}
.pagination_buttons {
  widows: auto;
  height: 40px;
  list-style: none;
  display: flex;
  justify-content: right;
  align-items: center;
  font-size: 12px;
  padding: 2px;
}

.pagination_buttons a {
  margin: 8px;
}
.pagination_active {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: #528ef7;
  color: white;
  cursor: pointer;
}

.invoice_heading {
  color: #003084;
}

.project-card-details {
  font-size: 12px;
  font-family: poppins;
}
.project-title {
  font-size: 13px;
}
.dropdown-input {
  width: 220px;
  height: 20px;
  border: none;
  border-radius: 5px;
  margin-right: 5px;
  &:focus {
    outline: none;
    border: none;
  }
}

.buttons {
  justify-content: end;
}

.resource-allocation-filter-wrapper {
  display: flex;
  justify-content: space-between;
  .searchbar-wrapper {
    width: 80%;
  }

  .filter-container {
    width: 15%;
    height: 100%;
    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      background: transparent;
      background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
      background-repeat: no-repeat;
      background-position-x: 100%;
      background-position-y: 50%;
      border: 1px solid $borderColor;
      border-radius: 10px;
      width: 100%;
      padding: 1rem;
      padding-right: 2rem;
      background-color: $white;
    }
  }
  .add-projectRepo-btn {
    background-color: $orange;
    color: $white;
    border-radius: 10px;
    @include typography("medium", 12px);
    height: 100%;
    width: 130px;
  }
  .export-report-wrapper {
    display: flex;
    position: relative;
    align-items: center;

    img {
      cursor: pointer;
    }

    .report-btn-wrapper {
      right: 0px;
      position: absolute;
      top: 40px;

      .export-btn {
        padding: 0px 9px;
        background-color: $orange;
        text-decoration: none;
        button {
          width: 176px;
          height: 50px;
          background-color: $orange;
          border: 0px;
          border-bottom: 1px solid rgba(255, 255, 255, 0.3);
          color: $white;
          @include typography("medium", 14px);
          text-align: left;
          padding: 0px 6px;
        }

        input {
          opacity: 0;
          position: absolute;
          left: 0;
          height: 50px;
          width: 100%;
          cursor: pointer;
        }
      }
    }
  }
}
