@import "../../scss/index.scss";

.pagination-wrapper {
  height: 50px;
  width: auto;
  display: flex;
  justify-content: flex-end;

  // .pagination-btn {
  //   height: 34px;
  //   font-size: 12px;
  //   border: 0;
  //   border-radius: 3px;
  //   margin: 6px;
  //   color: $paginationActive;
  //   border: 1px solid $paginationActive;
  // }

  .pagination-btn-active {
    height: 34px;
    width: 34px;
    color: $white;
    background-color: $paginationActive;
    font-size: 12px;
    border: 0;
    border-radius: 3px;
    margin: 6px;
  }

  .pagination-right-arrow {
    height: 34px;
    width: 34px;
    color: $lightGreyTitle;
    border: 0;
    border-radius: 3px;
    margin: 6px;
    background-color: $white;
  }
}
