@import "../../../scss/index.scss";
.bgcolor_red {
  background-color: rgb(249, 214, 214);
}
.bgcolor_green {
  background-color: rgb(217, 250, 217);
}
.bgcolor_yellow {
  background-color: rgb(255, 255, 220);
}
.bgcolor_white {
  background-color: rgb(234, 234, 234);
}
.table-margin-evaluation {

 
  thead {
    background-color: $tableHeaderBg;
    height: 45px;
    color: $white;
    vertical-align: middle;
    text-align: center;

    td {
      border: 1px solid;
    }
  }
  .more-previous-head{
    background-color: $white; 
  }

  td {
    border: none;
    // border: 1px solid $borderColor;
    vertical-align: middle;
    height: 45px;
    text-align: center;
  }
}

.evaluation-master-table {
  background-color: $white;
  thead {
    height: 45px;
    // td {
    //   // border: 1px solid $white;
    //   border: none !important;
    // }
  }
  .more-previous-body{
    color: $orange;
  }
}
.filter-wrapper-evaluation {
  display: flex;
  justify-content: space-between;

  .filter-container {
    width: 95%;

    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      background: transparent;
      background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
      background-repeat: no-repeat;
      background-position-x: 100%;
      background-position-y: 50%;
      border: 1px solid $borderColor;
      border-radius: 10px;
      width: 100%;
      padding: 1rem;
      padding-right: 2rem;
      background-color: $white;
    }
  }
  .export-report-wrapper {
    // width: 10%;
    display: flex;
    position: relative;
    align-items: center;

    img {
      cursor: pointer;
    }

    .report-btn-wrapper {
      right: 0px;
      position: absolute;
      top: 40px;

      .export-btn {
        padding: 0px 9px;
        background-color: $orange;

        button {
          width: 176px;
          height: 50px;
          background-color: $orange;
          border: 0px;
          border-bottom: 1px solid rgba(255, 255, 255, 0.3);
          color: $white;
          @include typography("medium", 14px);
          text-align: left;
          padding: 0px 6px;
        }
      }
    }
  }
}



