@import "../../scss/index.scss";

.tab-wrapper {
//   display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  height: 50px;
  width: auto;
  border: none;
  cursor: pointer;
  color: $greyTitle;
  @include typography("regular", 14px);
  line-height: 16px;
}

.tab-wrapper-active {
//   border-bottom: 6px solid $orange;
//   border-radius: 4px 4px 0px 0px;
  transition: 0.1s linear;
  color: $orange;
}

.hr-active {
    // width: 80%;
    position: relative;
    top: 14px;
    height: 6px; 
    border-radius: 4px 4px 0px 0px;
    background-color: $orange; 
    border-color: $orange; 
    color: $orange; 
    opacity: unset;
    margin: 0px;
}

.tab-icon {
  margin-right: 12px;
}

.sidebar-pills {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: $white;
  font-size: 1.1rem;
}
