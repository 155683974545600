@import "../../../scss/index.scss";

a {
  text-decoration: none;
}

.enrollment-course-container {
  background-color: $white;
  padding: 20px;
  margin-top: 15px;

  .grey-circle {
    border: 1px solid $lightGreyTitle;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    cursor: pointer;
  }

  .orange-checkbox {
    border: 1px solid $orange;
    height: 20px;
    width: 10%;
    background-color: $white;
    cursor: pointer;
  }

  .orange-checkbox-active {
    background-color: $orange;
    height: 20px;
    width: 10%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .enrollment-input {
    width: 100%;
    border: 1px solid $lightGreyTitle;
    height: 44px;
    padding-left: 15px;
    border-radius: 5px;
  }
  .enrollment-input::-webkit-input-placeholder {
    color: $lightGreyTitle;
    @include typography("medium", 14px);
  }

  .enrollment-input:disabled {
    background-color: $disabledInput;
  }

  .enrollment-input[type="date"]::-webkit-calendar-picker-indicator {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    background-image: url("../../../assets/calendar_icon.svg");
    background-repeat: no-repeat;
    background-position-x: 75%;
    background-position-y: 50%;
    padding: 1rem;
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 50%;
    border: 1px solid $lightGreyTitle;
    border-radius: 5px;
    width: 100%;
    padding: 1rem;
    padding-right: 2rem;
    background-color: $white;
    @include typography("regular", 12px);
  }

  textarea {
    width: 100%;
    border: 1px solid $lightGreyTitle;
    height: 88px;
    padding: 15px;
    border-radius: 5px;
  }

  textarea::-webkit-input-placeholder {
    color: $lightGreyTitle;
    @include typography("medium", 14px);
  }
}
