@import "../../../scss/index.scss";

.selected-modules{
    @include typography("medium", 12px);
    background-color: rgba(255, 255, 255, 1);
    box-shadow: rgba(0, 0, 0, 0.13) 0px 3px 6px, rgba(0, 0, 0, 0.2) 0px 3px 6px;
    height: auto;
    border: none;
    border-radius: 5px;
    padding: 10px;
    margin: 10px 15px 10px 15px ;
  }
  .add-module{
    color: $buttonBlue;
    cursor: pointer;
  }
  .remove-module{
    color: $orange;
    cursor: pointer;
  }
  .table-training-schedule {
    thead {
      background-color: $tableHeaderBg;
      height: 45px;
      color: $white;
      vertical-align: middle;
      text-align: center;
  
      td {
        border: 1px solid;
      }
    }
  
    td {
      border: 1px solid $borderColor;
      vertical-align: middle;
      height: 41px;
      text-align: center;
    }
  }
  
  .training-master-table {
    background-color: $white;
  
    thead {
      height: 41px;
      td {
        border: 1px solid $white;
      }
    }
  }
  .filter-wrapper-training-schedule {
    display: flex;
    justify-content: space-between;
  
    .searchbar-wrapper {
      width: 75%;
    }
  
    .filter-container {
      width: 20%;
  
      select {
        -webkit-appearance: none;
        -moz-appearance: none;
        background: transparent;
        background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
        background-repeat: no-repeat;
        background-position-x: 100%;
        background-position-y: 50%;
        border: 1px solid $borderColor;
        border-radius: 10px;
        width: 100%;
        padding: 1rem;
        padding-right: 2rem;
        background-color: $white;
      }
    }
}
    .project-heading {
      @include typography("normal", 20px);
      color: $projectHeading;
    }
    .project-name {
      @include typography("normal", 32px);
      color: $projectName;
    }
    .title-label{
        @include typography("normal", 12px);
        color: $projectHeading; 
    }
    .module-title-label{
      @include typography("normal", 16px);
      color: $projectHeading; 
  }
    .add-training-btn {
        background-color: $orange;
        color: $white;
        border-radius: 10px;
        @include typography("medium", 12px);
        height: 42px;
        width: 71px;
      }
    .add-disable-btn{
        pointer-events: none;
        background-color: $lightGreyTitle;
        color: $white;
        border-radius: 10px;
        @include typography("medium", 12px);
        height: 42px;
        width: 71px;
    }
    .add-disable-btn:hover{
      pointer-events: none;
      background-color: $lightGreyTitle;
      
  }
    .rotate-arrow-icon {
        transform: rotate(270deg);
    }
    .rotate-upgrade-icon{
        transform: rotate(180deg);
    }
    .expansion-heading{
        @include typography("normal", 10px);
        color: $projectHeading; 
    }


