@import "../../../scss/index.scss";

.filter-wrapper-bench-mgmt {
    display: flex;
    justify-content: space-between;
    .searchbar-wrapper {
      width: 55%;
    }
  
    .filter-container {
      width: 20%;
      height: 100%;
      select {
        -webkit-appearance: none;
        -moz-appearance: none;
        background: transparent;
        background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
        background-repeat: no-repeat;
        background-position-x: 100%;
        background-position-y: 50%;
        border: 1px solid $borderColor;
        border-radius: 10px;
        width: 100%;
        padding: 1rem;
        padding-right: 2rem;
        background-color: $white;
      }
    }
    .export-report-wrapper {
      display: flex;
      position: relative;
      align-items: center;
  
      img {
        cursor: pointer;
      }
  
      .report-btn-wrapper {
        right: 0px;
        position: absolute;
        top: 40px;
        .export-btn {
          padding: 0px 9px;
          background-color: $orange;
          text-decoration: none;
          button {
            width: 176px;
            height: 50px;
            background-color: $orange;
            border: 0px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.3);
            color: $white;
            @include typography("medium", 14px);
            text-align: left;
            padding: 0px 6px;
          }
          input {
            opacity: 0;
            position: absolute;
            top: 100px;
            left: 0;
            height: 50px;
            width: 100%;
            cursor: pointer;
          }
        }
      }
    }
  }
.project-details-card{
    padding: 20px;
    border: none;
    border-radius: 0%;
    box-shadow: none;
    .project-title{
        @include typography("normal", 20px);
    }
    .employee-name{
        @include typography("normal", 32px);
    }
    .status-title{
        @include typography("normal", 14px);
    }
    .title-color{
        color: $grey;
    }
     
}
.assign-link{
    color : $orange;
    cursor: pointer;
    &:hover{
        color : $orange; 
    }
}
.bench_text {
  color : $activeText;
  &:hover{
      color : $activeText; 
  }
}
.font-bench{
  @include typography("medium", 12px);
}
.marginTop-Emp {
margin-top: 10px;
}