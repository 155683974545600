@import "../../scss/index.scss";

.invoice-card {
  border-radius: 0px;
  border: none;
  box-shadow: none;
  overflow-x: hidden;
  .back-button {
    cursor: pointer;
    .backIcon {
      object-fit: contain;
      width: 25px;
      height: 25px;
    }
    .back-text {
      color: $orange;
      @include typography("medium", 16px);
    }
  }
  .project-heading {
    @include typography("normal", 20px);
    color: $projectHeading;
  }
  .project-name {
    @include typography("normal", 32px);
    color: $projectName;
  }
  .project-details {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  .invoice-details-label {
    color: $projectHeading;
    @include typography(normal, 14px);
  }
  .invoice-details-values {
    color: $projectName;
    @include typography(normal, 14px);
  }
  .invoice-wrap-details-values {
    color: $projectName;
    @include typography(normal, 14px);
    white-space: nowrap;    
  }
}
