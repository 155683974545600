.sidebardata {
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 200px;
  height: 40px;
  padding: 0 0 0 0;
  margin-left: -5%;
font-size: 14px;

}
.Link {
  display: flex;
  align-items: center;
  word-wrap: break-word;
  font-size: 13px;
  text-decoration: none;
  color: black;
  display: block;
  &:hover {
    width: 100%;
    background-color: #fff9f4;
    color: #27427A;
    margin-left: -5px;
  }
  &.active{
    width: 100%;
    background-color: #fff9f4;
    color: #27427A;
  }
}
.sidebar {
  border-right: 2px solid rgb(161, 161, 161);
  font-size: 14px;
}
.left__card {
  display: flex;
  font-size: 14px;
  width: 100%;
  flex-direction: row;
  margin: 10px 0 0 0;
  padding: 20px 10px 20px 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
.right__card {
  display: flex;
  width: 100%;
  flex-direction: row;
  padding: 10px;
  margin-left: 10px;
}
