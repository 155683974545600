.notification-size{
  font-size: 13px;
}

.header {
  height: 70px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: fixed;
  margin: 0 auto;
  left: 0;
  right: 0;
  z-index: 1;
  background-color: #ffffff;
  padding-left: 17px;
  // background-image: linear-gradient(#ff5717, #ff7f06);

  .spotlight-logo {
    width: 249px;
    height: 70px;
    cursor: pointer;
  }

  .header-search {
    width: 550px;
    margin: 16px 3rem;

    input {
      width: 550px;
      height: 40px;
      padding: 12px;
      border-radius: 20px;
      border-color: #c3bedd;
      background-color: ghostwhite;
      // box-shadow: 0 1px 3px 0 rgba(225, 228, 238, 0.28);
      outline: none;
    }

    .search-icon {
      position: absolute;
      left: 810px;
      top: 28px;
    }
  }

  .header-logos {
    display: flex;
    flex-direction: row;
    justify-content: right;
    padding: 20px;
    position: absolute;
    right: 0px;
    gap: 45px;

    .notification_icon {
      position: unset;
      width: auto;
    }

    .user_icon {
      width: 20px;
      height: 25px;
      margin: 3px 12px;
    }

    .btn-container {
      position: relative;
      .btn {
        display: flex;
        // min-width: 65px;
        height: 40px;
        width: 40px;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        gap: 0 0.5rem;
        position: relative;
        color: #343a54;
        background: ghostwhite;
        // border: transparent;
        // border-color: #262143;
        // box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
        //   0 4px 6px -2px rgba(0, 0, 0, 0.05);
        border-radius: 50%;
        padding: 3px 6px;
        outline: 0;
        transition: 0.3s ease-in-out all;
        &:hover {
          background-color: #fff;
        }
      }

      .dropdown {
        position: fixed;
        top: 56px;
        // left: 0;
        right: 10px;
        width: auto;
        background: ghostwhite;
        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
          0 2px 4px -1px rgba(0, 0, 0, 0.06);
        padding: 3px;
        text-align: center;
        visibility: hidden;
        border-radius: 3px;
        border: 1px solid #999;
        box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
          0 4px 6px -2px rgba(0, 0, 0, 0.05);
        transition: 0.3s ease-in-out all;
        &:hover {
          background-color: #fff;
        }
      }
      .show-dropdown {
        visibility: visible;
      }
      .dropdown-btn {
        background: transparent;
        border-color: transparent;
        color: #343a54;
        cursor: pointer;
        display: flex;
        gap: 0 0.3rem;
        padding: 0px 1px;
        height: 28px;
        outline: 0;
        align-items: center;
      }
      .image-profile{
       clip-path: circle();
      }
    }
  }
}

.icon-button__badge {
  position: absolute;
  left: 23px;
  top: 5px;
  width: 10px;
  height: 10px;
  background: red;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.notification-list {
  position: absolute;
  visibility: hidden;
  top: 59px;
  left: -300px;
  background-color: #fff;
  width: 470px;
  border-radius: 5px;
  padding: 5px;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  transition: 0.3s ease-in-out all;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
  max-height: 550px;
  overflow-y: auto;
}
.show-notification {
  visibility: visible;
}
.notification {
  // padding: 2px 0.5px;
  border-bottom: 1px solid #a9a9a9;
  // margin-bottom: 10px;
  margin-left: 20px;
  height: auto;
}
.notification-div{
  margin-top: 10px;
  font-size: 16px;
}

.notification-subject{
  margin-top: 10px;
  color: #27427a;
font-weight:600;
cursor: pointer;
&:hover{
  color : #3c5fa5;
}
}
// .notification-title{
//   margin-right: 250px;
 
// }
.notification-close{
  height: 20px;
  cursor: pointer;
  &:hover{
    background-color: rgb(253, 4, 4);
    border-radius: 15px;
  }
}
.notification-time{
  margin-left: 290px;
}
.toggle-switch{
  margin-top: 20px;
  margin-left: 400px;
}
.align-center-text{
  text-align: center;
}

.notification-icon {
  position: relative;
  display: inline-block;
}

.notification-dot {
  position: absolute;
  top: 10px;
  right: 11px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: rgb(206, 18, 18);
}
.icon-user{
  width: 17px;
  height: 17px;
}
.logout-icon{
  color:rgb(206, 18, 18)
}
