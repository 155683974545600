@import "../../scss/index.scss";
.width-search{
  width: 100% !important;
}
.heading-button-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  h2 {
    @include typography("semi-bold", 20px);
    color: $greyTitle;
    margin: 0px;
  }
}

.filter-wrapper {
  display: flex;
  justify-content: space-between;

  .emp-searchbar-wrapper {
    // width: 100%;
  }
}

.show-entries-wrapper {
  display: flex;
  justify-content: space-between;

  .select-text {
    display: flex;
    gap: 15px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    gap: 15px;
    @include typography("medium", 14px);
    color: $lightGreyTitle;
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 50%;
    width: auto;
    border: 0;
    padding: 10px;
    padding-right: 2rem;
    background-color: $white;
  }
}

.roleName:hover {
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
}

.role-margin{
  margin: 0px 10px;
}
