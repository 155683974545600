@import "../../../scss/index.scss";

.cancel-btn {
  border: 2px solid $orange;
  height: 42px;
  width: 200px;
  color: $orange;
}

.cancel-btn:hover {
    border: 2px solid $orange !important;
    height: 42px !important;
    // width: 200px !important;
    color: $orange !important;
  }

.add-submit-button {
  height: 42px;
  width: 200px;
  color: $white;
  background-color: $tableHeaderBg;
}

.add-submit-button:hover {
    height: 42px !important;
    // width: 200px !important;
    color: $white !important;
    background-color: $tableHeaderBg !important;
  }