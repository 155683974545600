@import "../../scss/index.scss";


.margin-top-inseed{
  margin-top: 48px;
}
.module-heading-button-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  h2 {
    @include typography("semi-bold", 20px);
    color: $greyTitle;
    margin: 0px;
  }

  .add-module-btn {
    background-color: $orange;
    color: $white;
    border-radius: 10px;
    @include typography("medium", 12px);
    height: 42px;
    width: 131px;
  }
}
.filter-module-wrapper {
  display: flex;
  justify-content: space-between;

  .module-searchbar-wrapper {
    width: 75%;
  }

  .module-filter-container {
    width: 20%;

    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      background: transparent;
      background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
      background-repeat: no-repeat;
      background-position-x: 100%;
      background-position-y: 50%;
      border: 1px solid $borderColor;
      border-radius: 10px;
      width: 100%;
      padding: 1rem;
      padding-right: 2rem;
      background-color: $white;
    }
  }

  .export-report-module-wrapper {
    display: flex;
    position: relative;
    align-items: center;

    img {
      cursor: pointer;
    }

    .report-module-btn-wrapper {
      right: 0px;
      position: absolute;
      top: 40px;

      .export-module-btn {
        padding: 0px 9px;
        background-color: $orange;

        button {
          width: 176px;
          height: 50px;
          background-color: $orange;
          border: 0px;
          border-bottom: 1px solid rgba(255, 255, 255, 0.3);
          color: $white;
          @include typography("medium", 14px);
          text-align: left;
          padding: 0px 6px;
        }

        input {
          opacity: 0;
          position: absolute;
          top: 100px;
          left: 0;
          height: 50px;
          width: 100%;
          cursor: pointer;
        }
      }
    }
  }
}
.show-entries-wrapper {
  display: flex;
  justify-content: space-between;

  .select-text {
    display: flex;
    gap: 15px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    gap: 15px;
    @include typography("medium", 14px);
    color: $lightGreyTitle;
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 50%;
    width: auto;
    border: 0;
    padding: 10px;
    padding-right: 2rem;
    background-color: $white;
  }
}
.module-name-hyperlink {
  @include typography("medium", 12px);
  cursor: pointer;
  color: $buttonBlue;
}

