@import "../../scss/index.scss";

.profile-tabs-container {
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px solid  $borderColor;
  gap: 40px;
  padding-left: 28px;
}

.profile-containers {
  padding: 35px 28px;

  h5 {
    @include typography("semi-bold", 14px);
    color: $nameHeader;
    // margin-bottom: 20px;
  }
}

.add-employee-details-skills {
  border-radius: 18px !important;
  height: 30px !important;
  width: 70px !important;
}
.alertSection{
  width:400px;
  display:flex;
  justify-content: center;
  flex-direction: column;
    align-items: center;
  @include typography("bold", 16px);
  color:red;
}
