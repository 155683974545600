@import "../../scss/index.scss";

.card {
  border: none;
  box-shadow: none;
  .content {
    @include typography("regular", 14px);
    color: $tableBodyTitle;
  }
}
