// @import './variables/variables.scss';
// @import './variables/color-variables.scss';
// @import './variables/typography.scss';

@import "./reset.scss";

@import "./app-scss/index.scss";

.xyz {
  text-align: center;
}
.backgroundDisabledColor {
  background-color: lightgray !important;
  opacity: 0.65 !important;
}
