@import "../../../scss/index.scss";

.assessment-heading-button-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  h2 {
    @include typography("semi-bold", 20px);
    color: $greyTitle;
    margin: 0px;
  }

  .add-assessment-btn {
    background-color: $orange;
    color: $white;
    border-radius: 10px;
    @include typography("medium", 12px);
    height: 42px;
    width: 135px;
  }
}

.filter-assessment-wrapper {
  display: flex;
  justify-content: space-between;

  .assessment-select-batch {
    width: 20%;
  }

  .assessment-searchbar-wrapper {
    width: 75%;
  }

  .assessment-filter-container {
    width: 20%;

    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      background: transparent;
      background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
      background-repeat: no-repeat;
      background-position-x: 100%;
      background-position-y: 50%;
      border: 1px solid $borderColor;
      border-radius: 10px;
      width: 100%;
      padding: 1rem;
      padding-right: 2rem;
      background-color: $white;
    }
  }
}
// table th:last-child {
//   width: 200px;
// }
