@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap");
@import "~bootstrap/scss/bootstrap";
@import "./scss/index.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
}

.row {
  margin-left: 0px;
  margin-right: 0px;
}

.modal-header {
  border-bottom: 0 none;
}

//sidebar
.sidebaricon {
  height: 20px;
  width: 20px;
}

//Employeedb
*body {
  background-color: #f2f6fb;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  font-family: "Poppins";
  table-layout: fixed;
  position: relative;
  overflow-y: auto !important;
  overflow-x: hidden !important;
}

body::-webkit-scrollbar {
  width: 8px;
  cursor: pointer;
}

body::-webkit-scrollbar-thumb {
  background-color: $viewJobDisable;
  border-radius: 4px;
  cursor: pointer;
}

body::-webkit-scrollbar-thumb:hover {
  background-color: $philippineGrey;
}

//@Vikashgupta

// Global CSS Setup ----------

//form
.form-control {
  // box-shadow: rgba(244, 168, 127, 0.25) 0px 2px 5px -1px,
  //   rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  // background-color: #fff9f4;
  border-radius: 5px;
  font-size: 12px;
  height: 33px;
}

.uppercase {
  text-transform: uppercase;
}

.form-label {
  font-size: 14px;
  color: $black;
  white-space: nowrap;
}

.fresherbox {
  background-color: #343a54;
  width: 240px;
  color: #ffffff;
  border-radius: 5px;
}

.textarea {
  box-shadow: rgba(244, 168, 127, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  height: 8rem;
  width: 100%;
  border: none;
  border-radius: 5px;
  border-radius: 10px;
  padding: 0.5rem;
}

.required {
  color: red;
}

.modal-dialog {
  max-height: calc(100vh - 50px);
}

.dropdown-select {
  box-shadow: rgba(244, 168, 127, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  border-radius: 5px;
  font-size: 12px;
}

.btn-disabled {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.3 !important;
  color: $white !important;
  background-color: $tableHeaderBg !important;
}

.modal-container {
  display: flex !important;
  align-items: center;

  .modal-title {
    @include typography("medium", 20px);
    color: $nameHeader;
  }
}

//----------------------

.add {
  margin-left: 1180px;
  display: flex;
  display: inline;
  word-spacing: 15px;
  align-items: flex-end;
  margin-bottom: 5px;
  width: 80px;
  height: 30px;
  border-radius: 5px;
  border: none;
  color: whitesmoke;
  background-color: #528ef7;
  margin-top: 20px;
}

.pag {
  justify-content: end;
  margin-top: 20px;
  margin-left: 60px;
}

// //Employeedb

.card {
  width: auto;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

//edit
.editemp {
  display: flex;
  flex-direction: column;
}

// pagination.module.scss

.paginationWrapper {
  padding: 2rem 0;
  display: flex;
  justify-content: center;
}

.separator {
  width: 1rem;
  margin: 0 0.25rem;
}

.pageItem {
  background: transparent;
  border: none;
  height: 2rem;
  width: 2rem;
  margin: 0 0.25rem;
  border-radius: 50%;
  font-weight: 600;
  color: #17142a;

  &:hover {
    text-decoration: underline;
  }

  &:focus {
    outline: 0;
  }
}

.active {
  background-color: #343a54;
  color: white;
}

.sides {
  box-shadow: transparent 0px 0px 0px 1px, transparent 0px 0px 0px 4px,
    rgba(0, 0, 0, 0.18) 0px 2px 4px;

  &:hover {
    text-decoration: none;
    box-shadow: transparent 0px 0px 0px 1px, transparent 0px 0px 0px 4px,
      rgba(0, 0, 0, 0.12) 0px 6px 16px;
  }
}

// Table styles
.table {
  .table-main {
    text-align: center;
    font-family: "Poppins", sans-serif;
    font-size: 13px;
    font-weight: 400 !important;
  }
  .table-head {
    font-size: 12px;
    white-space: nowrap;
  }

  .table-head-row {
    background-color: #1853a2;
    vertical-align: middle;
    color: white;
    height: 57px;
    font-weight: light;
    padding: 2px 0px;
    border: 1px solid;
  }

  .table-body {
    height: 50px;
    background-color: $white;

    .table-body-row {
      font-size: 11px;
      height: 57px;
      vertical-align: middle;

      td {
        border: 1px solid #dbd8d8;
      }
    }
  }
}

.btn-wrapper {
  display: flex;
  justify-content: flex-end;
  padding-right: 0px;
}

//table buttons style
.status-btn {
  // padding: 2px 6px 2px 6px;
  font-size: small;
  width: 100px;
}

//table show entries style
.show-entries-wrapper {
  font-size: 14px;
  font-family: poppins;

  .select-div {
    height: 25px;
    width: 50px;
    margin: 6px 6px;
    border: none;
    border-radius: 2px;
    outline: none;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  }
}

//Card component for Experience page
.experience-card {
  background-color: #fff9f4;
  width: 300px;
  max-height: auto;
  padding: 10px;
  margin: 10px;
  font-size: 13px;
}

.edit-icon {
  color: blue;
  border: none;
  background-color: none;
}

//Add pages card
.add-card {
  width: 55rem;
  padding: 40px;
  padding-bottom: 10px;
  margin-left: 4rem;
}

.addpage-buttons {
  width: 100px;
  margin: 10px;
}

//edit and delete action btn

.edit {
  height: 20px;
  width: 15px;
}

.delete {
  margin-left: 10px;
  width: 15px;
}

#active {
  background-color: #000000;
  opacity: 39%;
  // width: 230px;
  // height: 45px;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  transition: 0.5s;
  transition-delay: block;
  color: #ffffff;
}

.buttonsize {
  font-size: 12px;
}

.no-data {
  text-align: center;
  font-size: 12px;
  margin-top: 20px;
}

.custom-table-head-row {
  font-size: 12px;
  white-space: nowrap;
  height: 40px;
  font-weight: bold;
}

.sorting-cursor {
  cursor: pointer;
}

.height-one-rem {
  height: 1rem;
}
