@import "../../scss/index.scss";
.remarks-cell {
  max-width: 200px;
  word-wrap: break-word;
}
.note-text {
  @include typography("regular", 10px);
  color: $onHold;
  padding-bottom: 5px;
}

.employment_icon {
  color: $philippineGrey !important;
  font-size: 20px;
}

.job-title {
  @include typography("semi-bold", 20px);
  color: $buttonBlue;
}
.job-desc {
  font-size: 13px;
}
.line-hr {
  border: 1px solid $borderColor;
}
.disable-card {
  background-color: $light-gray;
}
.disable-title {
  color: $grey;
}
.edit-resource-icon {
  background-color: $white;
  border: none;
}
.edit-disable-resource {
  background-color: $light-gray;
  border: none;
}
.view-referral-list-link {
  @include typography("normal", 14px);
  color: $draftBtnBorder;
}
.text-height {
  height: 110px;
}
.button-gap {
  padding-inline: 10px;
}
.margin-button {
  margin-left: 20px;
}
.export-report-wrapper-skillLinks {
  z-index: 1;
  // width: 10%;
  display: flex;
  position: relative;
  align-items: center;

  img {
    cursor: pointer;
  }

  .report-btn-wrapper {
    right: 0px;
    position: absolute;
    top: 40px;

    .export-btn {
      padding: 0px 9px;
      background-color: $orange;

      button {
        width: 176px;
        height: 50px;
        background-color: $orange;
        border: 0px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.3);
        color: $white;
        @include typography("medium", 14px);
        text-align: left;
        padding: 0px 6px;
        cursor: pointer;
      }

      input {
        opacity: 0;
        position: absolute;
        top: 50px !important;
        left: 0;
        height: 50px;
        width: 100%;
        cursor: pointer;
      }
    }
  }
}
.upload-jd-button {
  border: 1px solid $orange;
  border-radius: 18px;
  color: $orange;
  @include typography("regular", 12px);
  height: 30px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  background-color: $white;
  cursor: pointer;
}
.upload-width {
  width: 65px;
}
.image-size-condition {
  font-size: 10px;
}
.view-jd-btn {
  border: 1px solid $orange;
  border-radius: 10px;
  color: $orange;
  @include typography("regular", 12px);
  width: 114px;
  height: 42px;
  padding: 12px 16px 12px 16px;
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: $white;
}
.view-job-btn {
  background-color: $orange;
  color: $white;
  border: none;
  border-radius: 10px;
  @include typography("medium", 12px);
  height: 42px;
  width: 87px;
}
.view-referral-list-btn {
  width: 142px;
}
.disable-button {
  background-color: $viewJobDisable;
  color: $white;
  cursor: default;
  &:hover {
    background-color: $viewJobDisable;
    color: $white;
  }
}
.disable-outline-btn {
  border: 1px solid $grey;
  color: $grey;
  cursor: default;
  &:hover {
    color: $grey;
  }
}
.sub-title {
  @include typography("normal", 14px);
  color: $grey;
}
.container {
  display: block;
  position: relative;
  padding-left: 20px;
  margin-top: 10px;
  cursor: pointer;
  @include typography("normal", 14px);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.checkbox-ijp {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkmark {
  position: absolute;
  top: 3px;
  left: 2px;
  height: 15px;
  width: 15px;
  border: 1px solid $black;
  background-color: $white;
  border-radius: 50%;
}
.checkbox-ijp:hover ~ .checkmark {
  border: 1px solid $black;
  background-color: $white;
}

.checkbox-ijp:checked ~ .checkmark {
  border: none;
  background-color: $activeText;
}
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.checkbox-ijp:checked ~ .checkmark:after {
  display: block;
}
.container .checkmark:after {
  left: 5px;
  top: 2px;
  width: 4px;
  height: 8px;
  border: solid $white;
  border-width: 0 1px 1px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.desclaimer-card {
  border: 1px solid $ratingWarning;
  background-color: $warningBg;

  .title {
    color: $ratingWarning;
    @include typography("semi-bold", 14px);
  }
  .bullet-text {
    color: $ratingWarning;
    @include typography("medium", 14px);
  }
}
.skill-links-btns {
  .common-css {
    width: 91px;
    height: 24px;
    @include typography("normal", 12px);
    border-radius: 5px;
  }
  .rejected-btn {
    border: 1px solid $rejetedBtnBorder;
    color: $rejetedBtnBorder;
    background-color: $rejectedBg;
    &:hover {
      background-color: $rejectedBg;
    }
  }
  .approved-btn {
    border: 1px solid $approvedBtnBorder;
    color: $approvedBtnBorder;
    background-color: $approvedBg;
    &:hover {
      background-color: $approvedBg;
    }
  }
  .updated-btn {
    border: 1px solid $updatedBtnBorder;
    color: $updatedBtnBorder;
    background-color: $updatedBg;
    &:hover {
      background-color: $updatedBg;
    }
  }
  .draft-btn {
    border: 1px solid $draftBtnBorder;
    color: $draftBtnBorder;
    background-color: $draftBg;
    &:hover {
      background-color: $draftBg;
    }
  }
}
.time-span {
  @include typography("semi-bold", 14px);
}
.ux-list {
  list-style: none;
  padding-left: 0;
  .ux-li {
    @include typography("semi-bold", 14px);
    position: relative;
    padding-left: 8px;
    margin-bottom: 0px;
  }

  .ux-li::before {
    content: "•";
    position: absolute;
    left: 0;
  }
}
.employee-history-card {
  padding: 20px;
  border: none;
  border-radius: 0%;
  box-shadow: none;
  .project-title {
    @include typography("normal", 20px);
  }
  .employee-name {
    @include typography("normal", 32px);
  }
  .status-title {
    @include typography("normal", 14px);
  }
  .title-color {
    color: $grey;
  }
}

.custom-form-check {
  .form-check-input {
    background-image: url("data:image/svg+xml,<svg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27><circle r=%273%27 fill=%27%23fff%27/></svg>") !important;
    background-color: $approvedBtnBorder !important;
  }
  .form-check-input:checked {
    background-color: $rejetedBtnBorder !important;
    border-color: $rejetedBtnBorder !important;
    background-image: url("data:image/svg+xml,<svg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27><circle r=%273%27 fill=%27%23fff%27/></svg>") !important;
  }

  .form-check-input:focus {
    outline: 0;
    box-shadow: none !important;
  }
}

.table-body-row td.remarks-cell {
  white-space: normal;
  word-break: break-word;
}

.widthEightyFive {
  width: 85% !important;
}
