.Login-Button {
  min-width: 100%;
  height: 35px;
  cursor: pointer;
  margin-right: 0.25em;
  margin-top: 0.5em;
  border: none;
  border-radius: 5px;
  background-image: linear-gradient(#ff5717, #ff7f06);
  color: #fefefe;
}

.Login-Button:hover  {
  background-image: linear-gradient(#343a54, #343a54);
  color: #fefefe;
}

.logo {
  margin: 2em;
  margin-left: 6em;
  width: 10rem;
}
.form {
  margin: 10px;
  font-size: 15px;
}
.pwd-container {
  position: relative;
}

.pwd-container input {
  padding: 5px 30px 5px 10px;
}

.pwd-container img {
  cursor: pointer;
  position: absolute;
  width: 20px;
  right: 28px;
  top: 32px;
}
.error {
  color: red;
  font-size: 12px;
  position: absolute;
}
.register {
  text-decoration: none;
  color: #495057;
  font-size: 20px;
  font-weight: bold;
}
.password {
  display: flex;
  justify-content: space-between;
  text-decoration: none;
}
.forgotpw {
  text-decoration: none;
  color: #495057;
  font-size: 13px;
}
.forgotpwdiv {
  text-align: right;
}
.account {
  font-size: 15px;
}
