@import "../../../scss/index.scss";

.redeem-heading-button-wrapper {
  // width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  h2 {
    @include typography("semi-bold", 20px);
    color: $greyTitle;
    margin: 0px;
  }

  .export-report-wrapper {
    // width: 10%;
    display: flex;
    position: relative;
    align-items: center;

    img {
      cursor: pointer;
    }

    .report-btn-wrapper {
      right: 0px;
      position: absolute;
      top: 40px;

      .export-btn {
        padding: 0px 9px;
        background-color: $orange;

        button {
          width: 176px;
          height: 50px;
          background-color: $orange;
          border: 0px;
          border-bottom: 1px solid rgba(255, 255, 255, 0.3);
          color: $white;
          @include typography("medium", 14px);
          text-align: left;
          padding: 0px 6px;
        }

        input {
          opacity: 0;
          position: absolute;
          top: 100px;
          left: 0;
          height: 50px;
          width: 100%;
          cursor: pointer;
        }
      }
    }
  }
}

.radeem-actio-btn {
  @include typography("medium", 12px);
  width: 91px;
  height: 24px;
  border-radius: 5px;
}
.redeem-btn {
  color: $activeText;
  border: 1px solid $activeText;
  background-color: $lightgreen;
}
.redeem-btn-disabled {
  cursor: none;
  pointer-events: none;
  color: $greenDisable;
  border: 1px solid $greenDisable;
  background-color: $lightgreenDisable;
}

.accepted-btn {
  color: $tableHeaderBg;
  border: 1px solid $tableHeaderBg;
  background-color: $blueBackground;
}

.accepted-btn-disabled {
  cursor: none;
  pointer-events: none;
  color: $blueDisable;
  border: 1px solid $blueDisable;
  background-color: $lightblueDisable;
}

.redeem-allocated-btn {
  color: $approvedStatus;
}
.redeem-dellocated-btn {
  color: $onHold;
}
.redeem-settled-btn {
  color: $updatedStatus;
}
.table-head-row:last-child {
  column-span: 2;
}

.color-white {
  color: white;
}

.text-decoration-color-white {
  text-decoration: none;
  color: white;
}

.border-none {
  border: none;
}
