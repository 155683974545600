@import "../../../scss/index.scss";
.coaching-progress-heading-button-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  h2 {
    @include typography("semi-bold", 20px);
    color: $greyTitle;
    margin: 0px;
  }

  .coaching-progress-view-btn {
    background-color: $orange;
    color: $white;
    border-radius: 10px;
    @include typography("medium", 12px);
    height: 42px;
    width: 140px;
  }
}
.filter-coaching-progress-wrapper {
  display: flex;
  justify-content: space-between;

  .coaching-progress-searchbar-wrapper {
    width: 75%;
  }

  .coaching-progress-filter-container {
    width: 20%;

    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      background: transparent;
      background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
      background-repeat: no-repeat;
      background-position-x: 100%;
      background-position-y: 50%;
      border: 1px solid $borderColor;
      border-radius: 10px;
      width: 100%;
      padding: 1rem;
      padding-right: 2rem;
      background-color: $white;
    }
  }
}

.coaching-status-approved {
  @include typography("medium", 12px);
  color: $approvedStatus;
}
.coaching-status-rejected {
  @include typography("medium", 12px);
  color: $rejectedStatus;
}
.coaching-progress-table {
  background-color: $white;
  td {
    border: 1px solid $borderColor;
  }
}

.table-margin {
  margin-top: 18px;
}

.card-text {
  text-decoration: none;
  @include typography("medium", 14px);
  color: $tableBodyTitle;
}
.card-text-label {
  @include typography("medium", 14px);
  color: $lightGreyTitle;
}
.draft-icon {
  object-fit: contain;
  height: 14px;
  width: 14px;
}
.coahing-inprogress-status-wip {
  @include typography("medium", 12px);
  color: $onHold;
}
.coahing-inprogress-status-completed {
  @include typography("medium", 12px);
  color: $approvedStatus;
}
.coahing-inprogress-status-reworks {
  @include typography("medium", 12px);
  color: $rejectedStatus;
}
.coaching-progress-button {
  border-radius: 18px;
  border: 1px solid $orange;
  @include typography("medium", 12px);
}
.file-size-text {
  @include typography("regular", 12px);
  color: $tableBodyTitle;
}
.rating-input {
  border-radius: 10px;
  border: 1px solid $borderColor;
  box-shadow: none;
  height: 25px;
  width: 60%;
  box-shadow: $boxShadow;
  padding: 5px;
}
.feedback-input {
  border-radius: 10px;
  border: 1px solid $borderColor;
  box-shadow: none;
  height: 25px;
  width: 80%;
  box-shadow: $boxShadow;
  padding: 5px;
}
.update-button {
  border-radius: 10px;
  background-color: $orange;
  color: $white;
  border: none;
  @include typography("medium", 12px);
}
.coaching-progress-searchbar-wrapper {
  width: 75%;
}
.upload-file-button {
  border: 1px solid $orange;
  border-radius: 18px;
  color: $orange;
  @include typography("regular", 12px);
  width: 90px;
  height: 30px;
  padding: 5px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  background-color: $white;
}
.new-session {
  @include typography("semi-bold", 16px);
  color: $tableBodyTitle;
}
.add-session-button {
  height: 42px;
  width: 200px;
  color: $white;
  background-color: $orange;
}
.add-session-btn-disabled {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.3 !important;
  color: $white !important;
  background-color: $orange !important;
}
