@import "../../../scss/index.scss";

.skills-container {
  // padding: 20px 50px;
  .main-title {
    color: $greyTitle;
    @include typography("medium", 14px);
    font-style: normal;
    line-height: normal;
  }
  .card-div-container {
    padding: 10px 0px;
    .table-conatiner {
      border-spacing: 1px;
      border-collapse: inherit;
      background: $white;
      .table-head {
        background: $tableHeaderBg;
        color: $white;
        padding: 10px;
        .table-td {
          color: var(--text-white, rgba(255, 255, 255, 0.9));
          line-height: normal;
          @include typography("medium", 14px);
          padding: 10px;
          text-align: center;
          border: 1px solid $borderColor;
        }
      }
      .table-body {
        border: 1px solid $borderColor;
        .table-td {
          color: var(--secondary-headings, $tableBodyTitle);
          @include typography("medium", 14px);
          padding: 10px;
          text-align: center;
          border: 1px solid $borderColor;
        }
      }
    }
  }

  .bank-card-div-container {
    padding: 10px 0px;
    .card-title {
      color: $greyTitle;
      @include typography("medium", 14px);
      line-height: normal;
    }
    .bank-card-content-container {
      display: flex;
      width: auto;
      flex-wrap: wrap;
      .card-content {
        width: 33%;
        padding: 10px 0px;
        .card-content-title {
          color: $lightGreyTitle;
          @include typography("regular", 14px);
          line-height: normal;
        }
        .card-content-data {
          color: $greyTitle;
          @include typography("regular", 14px);
          line-height: normal;
        }
      }
    }
  }
}


