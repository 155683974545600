@import "../../scss/index.scss";

.learning-development-link {
  color: $tableHeaderBg;
  text-decoration: underline;
}

.external-training-text {
  color: $orange;
  @include typography("regular", 12px);
}

.internal-training-text {
  color: $internalTraining;
  @include typography("regular", 12px);
}

.button-invoice {
  width: 90px;
  height: 30px;
}
.add-invoice {
  height: 30px;
}
.pagination_buttons {
  widows: auto;
  height: 40px;
  list-style: none;
  display: flex;
  justify-content: right;
  align-items: center;
  font-size: 12px;
  padding: 2px;
}

.pagination_buttons a {
  margin: 8px;
}
.pagination_active {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  // background-color: #528ef7;
  color: white;
  cursor: pointer;
}

// .invoice_heading {
//   color: #003084;
// }

.project-card-details {
  font-size: 12px;
  font-family: poppins;
}
.project-title {
  font-size: 13px;
}

.btn_accept {
  height: 30px;
  width: 30px;
  font-size: 12px;
  text-align: center;
}
.btn_reject {
  height: 30px;
  width: 30px;
  font-size: 12px;
  text-align: center;
}

// .completed {
//   color: #3c65b0;
// }
// .open {
//   color: #ff9c07;
// }
// .ongoing {
//   color: #28bca3;
// }
// .rejected {
//   color: #ef5467;
// }

.learning-dev-purpose-container {
  margin-top: 50px;

  h6 {
    @include typography("regular", 12px);
    color: $black;
    margin-bottom: 17px;
  }

  .learning-dev-purpose-wrapper {
    background-color: $white;
    border-radius: 10px;
    padding: 16px 20px 16px 16px;
    @include typography("regular", 14px);
    line-height: 21px;
    color: $greyTitle;
  }
}

.green-tick-btn {
  border: 1px solid $approvedStatus;
  background-color: $approvedBg;
  border-radius: 5px;
  display: flex;
  @include typography("regular", 9px);
  margin-right: 8px;
  width: 40px;
  height: 24px;
  justify-content: center;
  align-items: center;
  color: $approvedStatus;
}
.blue-tick-btn:disabled,
.blue-tick-btn[disabled] {
  border: 1px solid $buttonDisable;
  background-color: $buttonLightBule;
  color: $black;
}

.orange-cross-btn {
  border: 1px solid $orange;
  background-color: $orangeCrossBg;
  border-radius: 5px;
  display: flex;
  @include typography("regular", 9px);
  width: 40px;
  justify-content: center;
  align-items: center;
  color: $orange;
}
.orange-cross-btn:disabled,
.orange-cross-btn[disabled] {
  border: 1px solid $onHold;
  background-color: $buttonOrange;
  color: $black;
}

.new-text-status {
  color: $newText;
}

.accepted-text-status {
  color: $internalTraining;
}

.declined-text-status {
  color: $orange;
}

.disabled-tick-btn {
  border: 1px solid $lightblueDisable;
  background-color: $white;
  border-radius: 5px;
  display: flex;
  @include typography("regular", 9px);
  margin-right: 8px;
  width: 40px;
  height: 24px;
  justify-content: center;
  align-items: center;
  color: $lightblueDisable;
  cursor: not-allowed !important;
}

.approve-btn {
  height: 24px;
  width: 59px;
  @include typography("regular", 12px);
}

.width-fifty {
  width: 50%;
}

.width-fifty-five {
  width: 55%;
}

.margin-left {
  margin-left: 0px;
}
