@import "../../../scss/index.scss";

.trainee-master-table-container {
  .rotate-icon {
    transform: rotate(270deg);
  }
  .trainee-master-searchbar-wrapper {
    display: flex;
    justify-content: space-between;
    gap: 25px;

    .searchbar-wrapper {
      width: 40%;
    }

    .trainee-master-filter-container {
      width: 20%;

      select {
        -webkit-appearance: none;
        -moz-appearance: none;
        background: transparent;
        background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
        background-repeat: no-repeat;
        background-position-x: 100%;
        background-position-y: 50%;
        border: 1px solid $borderColor;
        border-radius: 10px;
        width: 100%;
        padding: 1rem;
        padding-right: 2rem;
        background-color: $white;
        @include typography("regular", 12px);
      }
    }

    .select-batch-wrapper {
      width: 20%;

      select {
        -webkit-appearance: none;
        -moz-appearance: none;
        background: transparent;
        background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
        background-repeat: no-repeat;
        background-position-x: 100%;
        background-position-y: 50%;
        border: 1px solid $borderColor;
        border-radius: 10px;
        width: 100%;
        padding: 1rem;
        padding-right: 2rem;
        background-color: $white;
        @include typography("regular", 12px);
      }
    }

    .export-report-wrapper {
      // width: 10%;
      display: flex;
       position: relative;
      align-items: center;

      img {
        cursor: pointer;
      }

      .report-btn-wrapper {
        right: 0px;
        position: absolute;
        top: 40px;

        .export-btn {
          padding: 0px 9px;
          background-color: $orange;

          button {
            width: 176px;
            height: 50px;
            background-color: $orange;
            border: 0px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.3);
            color: $white;
            @include typography("medium", 14px);
            text-align: left;
            padding: 0px 6px;
          }

          input {
            opacity: 0;
            position: absolute;
            top: 100px;
            left: 0;
            height: 50px;
            width: 100%;
            cursor: pointer;
          }
        }
      }
    }
  }

  table {
    thead {
      background-color: $tableHeaderBg;
      height: 45px;
      color: $white;
      vertical-align: middle;
      text-align: center;

      td {
        border: 1px solid;
      }
    }

    td {
      border: 1px solid $borderColor;
      vertical-align: middle;
      height: 41px;
    }
  }

  .training-master-table {
    background-color: $white;

    thead {
      height: 41px;

      td {
        border: 1px solid #ccc;
      }
    }
  }
}

.dropdown-menu {
  width: 100%;
  max-height: 120px;
  overflow-y: scroll;
  @include typography("regular", 12px);
}
