@import "../../../scss/index.scss";

.coaching-request-heading-button-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  h2 {
    @include typography("semi-bold", 20px);
    color: $greyTitle;
    margin: 0px;
  }

  .coaching-request-btn {
    background-color: $orange;
    color: $white;
    border-radius: 10px;
    @include typography("medium", 12px);
    height: 42px;
    width: 140px;
  }
}
.filter-coaching-request-wrapper {
  display: flex;
  justify-content: space-between;

  .coaching-request-searchbar-wrapper {
    width: 75%;
  }

  .coaching-request-filter-container {
    width: 20%;

    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      background: transparent;
      background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
      background-repeat: no-repeat;
      background-position-x: 100%;
      background-position-y: 50%;
      border: 1px solid $borderColor;
      border-radius: 10px;
      width: 100%;
      padding: 1rem;
      padding-right: 2rem;
      background-color: $white;
    }
  }
}
.select-coach-btn {
  border: 1px solid $tableHeaderBg;
  background-color: $buttonLightBule;
  color: $tableHeaderBg;
}
.request-sent-btn:disabled,
.request-sent-btn[disabled] {
  border: 1px solid $onHold;
  background-color: $buttonOrange;
  color: $onHold;
}
.select-coach-btn:disabled,
.select-coach-btn[disabled] {
  border: 1px solid $buttonDisable;
  background-color: $buttonLightBule;
  color: $buttonDisable;
}
.coachee-status-approved {
  @include typography("medium", 12px);
  color: $approvedStatus;
}
.coachee-status-rejected {
  @include typography("medium", 12px);
  color: $rejectedStatus;
}
.slot-status {
  @include typography("medium", 12px);
}
.in-progress {
  color: $approvedStatus;
}
.expired {
  color: $onHold;
}
.new {
  @include typography("medium", 12px);
  color: $newStatus;
}
.progress-header {
  h2 {
    @include typography("semi-bold", 20px);
    color: $greyTitle;
    margin: 0px;
  }

  .request-back-button {
    cursor: pointer;
    .backIcon {
      object-fit: contain;
      width: 25px;
      height: 25px;
    }
    .back-text {
      color: $orange;
      @include typography("medium", 16px);
    }
  }
}
// .accept-btn:disabled,
// .accept-btn[disabled] {
//   @include typography("medium", 12px);
//   color: $buttonBlue;
//   border: 1px solid $buttonBlue;
//   background-color: $buttonLightBule;
// }
// .reject-btn:disabled,
// .reject-btn[disabled] {
//   @include typography("medium", 12px);
//   color: $orange;
//   border: 1px solid $orange;
//   background-color: $rejectBackground;
// }
.coaching-request-btns {
  height: 52px;

  .accept-btn {
    border: 1px solid $tableHeaderBg;
    background-color: $blueTickBg;
    border-radius: 5px;
    @include typography("regular", 9px);
    margin-right: 8px;
    width: 30px;
    height: 20px;
  }
  .reject-btn {
    border: 1px solid $orange;
    background-color: $orangeCrossBg;
    border-radius: 5px;
    @include typography("regular", 9px);
    width: 30px;
    height: 20px;
  }
}
.coaching-request-table {
  background-color: $white;

  td {
    border: 1px solid $borderColor;
  }
}
.calendar-btn {
  position: relative;
  input {
    opacity: 0;
    position: absolute;
    top: 4;
    left: 42%;
    width: 15%;
    @include typography("medium", 20px);
    cursor: pointer;
  }
  input::-webkit-calendar-picker-indicator {
    cursor: pointer;
  }
}
/* Undo popup CSS */
.undo-popup {
  position: fixed;
  bottom: 20px;
  left: 20px;
  background-color: $black;
  color: $white;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 1;
  @include typography("normal", 12px);
}

.table-margin {
  margin-bottom: 50px;
}
